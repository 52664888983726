import { all, put, select, takeLatest } from 'redux-saga/effects';

import { parseCities } from '../../../utils/helpers';
import {
  selectCities,
  selectEthnics,
  selectReligions,
  selectSchools,
  selectVillages,
} from '../../catalogs/store/selectors';
import { parseSubjects } from '../components/helpers';
import { actions as subjectAction } from './reducer';

function* view(action) {
  const { subjectStore: subjects, surveyStore: surveys } = yield select();
  const allVillages = yield select(selectVillages);
  const allCities = yield select(selectCities);
  const ethnics = yield select(selectEthnics);
  const religions = yield select(selectReligions);
  const allSchools = yield select(selectSchools);

  const { filters } = action?.payload || {};
  const { data, dynamicFilters, dynamicSorts } = subjects;
  const { filters: surveyFilters } = surveys;
  const { year } = filters || surveyFilters || {};
  const cities = parseCities(allCities, year);
  const {
    pageSubjects: newSubjects,
    totalSubjects,
    subjectIds,
  } = parseSubjects(data, {
    ...(filters || subjects.filters),
    dynamicFilters,
    dynamicSorts,
    cities,
    ethnics,
    religions,
    allSchools,
    allVillages,
  });

  yield put(subjectAction.fetchViewSubjects({ data: newSubjects, totalSubjects, subjectIds }));
}

export default function* saga() {
  yield all([takeLatest(subjectAction.view, view)]);
}
