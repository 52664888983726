import _ from 'lodash';
import moment from 'moment';

moment.updateLocale('vi', {
  months: _.range(12).map((i) => `Tháng ${i + 1}`),
  monthsShort: _.range(12).map((i) => `Tháng ${i + 1}`),
  monthsParseExact: true,
  weekdays: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
  weekdaysShort: ['CN', 'HAI', 'BA', 'TƯ', 'NĂM', 'SÁU', 'BẢY'],
  weekdaysMin: ['CN', 'HAI', 'BA', 'TƯ', 'NĂM', 'SÁU', 'BẢY'],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[Hôm nay lúc] LT',
    nextDay: '[Ngày mai lúc] LT',
    nextWeek: 'dddd [tuần tới] LT',
    lastDay: '[Hôm qua lúc] LT',
    lastWeek: 'dddd [tuần trước] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s',
    past: '%s trước',
    s: 'vài giây',
    m: 'một phút',
    mm: '%d phút',
    h: '1 tiếng',
    hh: '%d tiếng',
    d: 'một ngày',
    dd: '%d ngày',
    M: 'một tháng',
    MM: '%d tháng',
    y: 'một năm',
    yy: '%d năm',
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

moment.defaultFormat = 'D/M/Y';

export const calculateMonthsAge = (birthDay, date, ageRule = 'month') => {
  switch (ageRule) {
    case 'month':
      return moment(date).startOf('months').diff(moment(birthDay).startOf('months'), 'months');
    case 'date':
      return Math.trunc(moment(date).diff(moment(birthDay), 'months', true));
    default:
      return -1;
  }
};

export const date2ObjectId = (date) => `${Math.floor(date / 1000).toString(16)}0000000000000000`;

export const objectId2Date = (objectId) => parseInt(objectId.substring(0, 8), 16) * 1000;

export const daysFromNow = (date) => moment(date).diff(moment().startOf('days'), 'days');

export const getSchoolYear = (config, date) => {
  const month = date.month() + 1;
  const year = date.year();
  const startMonth = config[year] ?? 8;

  return month >= startMonth ? year : year - 1;
};

export const getWeekDay = (date) =>
  ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'][moment(date).weekday()];

export const dateExport = () => moment().format('[Ngày] DD [Tháng] MM [Năm] YYYY');
