import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const dev = process.env.REACT_APP_STAGE !== 'production';

export const app = initializeApp(
  dev
    ? {
        apiKey: 'AIzaSyCUd88zuuyiCgJAvx2qkRNcADBC421tTZY',
        authDomain: 'elibot-dev.firebaseapp.com',
        databaseURL: 'https://elibot-dev.firebaseio.com',
        projectId: 'elibot-dev',
        storageBucket: 'elibot-dev.appspot.com',
        messagingSenderId: '506729298453',
        appId: '1:506729298453:web:576fecc722c3dfb828ec1c',
      }
    : {
        apiKey: 'AIzaSyDF1OCQcJEZzIy5tfJWk-EoDaVFYCaCkeo',
        authDomain: 'elibot-release.firebaseapp.com',
        databaseURL: 'https://elibot-release.firebaseio.com',
        projectId: 'elibot-release',
        storageBucket: 'elibot-release.appspot.com',
        messagingSenderId: '102917872857',
        appId: '1:102917872857:web:724c81f9e8f1dad9c9f2c8',
        measurementId: 'G-ZNL5W57L51',
      }
);

getAuth().languageCode = 'vi';

const getToken = (onSuccess, onError) => {
  getAuth()
    .currentUser.getIdToken(true)
    .then((token) => {
      onSuccess(token);
    })
    .catch((error) => {
      onError(error);
    });
};

export { getToken };
