import { Button, Col, Divider, Modal, Row, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACTIVE_YEAR, schoolYears, sortDistrictProvinces, unitTypesOptions } from '../../configs';
import { selectCities } from '../../features/catalogs/store/selectors';
import { useUserSlice } from '../../features/user/store';
import { parseCities, parseOptions } from '../../utils/helpers';
import useStyles from './styles';

function UnitSelector({ onClose }) {
  const S = useStyles();
  const allCities = useSelector(selectCities);
  const { actions } = useUserSlice();
  const dispatch = useDispatch();

  const [editor, setEditor] = useState({ year: ACTIVE_YEAR });
  const { unitType, year, province, district, ward } = editor;

  const footer = useMemo(() => {
    const result = [
      <Button danger key="logout" onClick={() => dispatch(actions.signOut())}>
        Đăng xuất
      </Button>,
    ];
    if (province) {
      result.unshift(
        <Button
          type="primary"
          key="join"
          onClick={() => dispatch(actions.joinUnit({ province, district, ward }))}
        >
          Đăng ký sử dụng
        </Button>
      );
    }
    return result;
  }, [actions, dispatch, province, district, ward]);

  const cities = useMemo(() => parseCities(allCities, year), [allCities, year]);
  const provinceOptions = parseOptions(cities);
  const districtOptions = parseOptions(
    cities?.[province]?.districts,
    !sortDistrictProvinces.includes(province)
  );
  const wardOptions = parseOptions(cities?.[province]?.districts?.[district]?.wards);

  return (
    <Modal
      title="Chọn đơn vị sử dụng"
      open
      width="90%"
      footer={footer}
      closeIcon={!onClose}
      onCancel={onClose}
    >
      <Divider />
      <Row gutter={16}>
        <Col span={4}>
          <Typography>Loại đơn vị</Typography>
          <Select
            className={S.select}
            options={unitTypesOptions}
            value={unitType}
            onChange={(value) => setEditor((prev) => ({ ...prev, unitType: value }))}
            placeholder="Chọn loại đơn vị"
          />
        </Col>
        {unitType ? (
          <>
            {['phuong', 'quan'].includes(unitType) ? (
              <Col span={5}>
                <Typography>Năm học</Typography>
                <Select
                  className={S.select}
                  options={schoolYears}
                  value={year}
                  onChange={(value) => setEditor((prev) => ({ ...prev, year: value }))}
                />
              </Col>
            ) : null}
            <Col span={5}>
              <Typography>Tỉnh</Typography>
              <Select
                showSearch
                className={S.select}
                options={provinceOptions}
                value={province}
                onChange={(value) =>
                  setEditor((prev) => ({ ...prev, province: value, district: '', ward: '' }))
                }
                placeholder="Chọn tỉnh"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Col>
            {unitType === 'tinh' ? null : (
              <Col span={5}>
                <Typography>Quận/Huyện</Typography>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  className={S.select}
                  options={districtOptions}
                  value={district}
                  onChange={(value) =>
                    setEditor((prev) => ({ ...prev, district: value, ward: '' }))
                  }
                  placeholder="Chọn quận/huyện"
                />
              </Col>
            )}
            {unitType === 'tinh' || unitType === 'quan' ? null : (
              <Col span={5}>
                <Typography>Phường/Xã</Typography>
                <Select
                  showSearch
                  className={S.select}
                  options={wardOptions}
                  value={ward}
                  onChange={(value) => setEditor((prev) => ({ ...prev, ward: value }))}
                  placeholder="Chọn phường/xã"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Col>
            )}
          </>
        ) : null}
      </Row>
    </Modal>
  );
}

UnitSelector.defaultProps = {
  onClose: undefined,
};

UnitSelector.propTypes = {
  onClose: PropTypes.func,
};

export default UnitSelector;
