import { notification } from 'antd';
import { getAuth, signOut as signOutFirebase } from 'firebase/auth';
import _ from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { backendService } from '../../../services';
import formatError from '../../../utils/formatError';
import { initializeGA } from '../../../utils/ga';
import { actions as catalogActions } from '../../catalogs/store/reducer';
import { actions as userActions } from './reducer';
import { selectUserInformation } from './selectors';

function* getActiveUnit(unitId, user) {
  const result = yield backendService.graphql(
    'database/units',
    'getUnit',
    `{
      getUnit(unit: "${unitId}") {
        id
        admins
        staffs
        members {
          email
          displayName
        }
        province
        ward
        district
        type
      }
    }`
  );
  if (result.kind === 'ok') {
    const { members, admins, ...rest } = result.data || {};
    const unit = { ...rest, admins };
    if (members) {
      const validMembers = {};
      _.forEach(members, ({ email, ...memberData }) => {
        validMembers[email] = memberData;
      });
      unit.members = validMembers;
    }
    if (_.includes(admins, user.email)) {
      _.set(user, ['isAdmin'], true);
    }
    if (user?.isSupport || unit?.members[user.email]) {
      yield put(
        catalogActions.getConfigs({
          province: unit.province,
          district: unit.district,
          ward: unit.ward,
        })
      );
      yield put(catalogActions.fetchUnitCatalog({ catalog: 'activeUnit', data: unit }));
    } else {
      throw new Error('Không có quyền truy cập đơn vị. Vui lòng chọn đơn vị khác.');
    }
  } else {
    notification.warning({
      message: 'Lỗi',
      description: 'Lấy thông tin đơn vị thất bại',
    });
  }
}

function* updateUserInformation(action) {
  const userInfo = yield select(selectUserInformation);
  const result = yield backendService.post('/api/updateUserInformation', {
    uid: userInfo.uid,
    information: action.payload,
  });
  if (result.kind === 'ok' && result.data) {
    yield put(userActions.fetchInformation({ ...userInfo, ...action.payload }));
    notification.success({
      message: 'Lưu thành công',
    });
    return;
  }
  notification.warning({
    message: 'Cập nhật thất bại',
    description: formatError(result),
  });
  yield put(userActions.fetchInformation(null));
}

function* joinUnit(action) {
  const { province, district, ward } = action.payload;
  try {
    if (!province) {
      notification.warning({
        message: 'Thông tin không hợp lệ',
        description: 'Thông tin tỉnh không được bỏ trống',
      });
      return;
    }
    let activeUnit = province;
    if (district) activeUnit = district;
    if (ward) activeUnit = ward;

    yield* updateUserInformation({ payload: { activeUnit } });
    window.location.reload();
  } catch (error) {
    yield put(userActions.fetchInformation(null));
    notification.warning({
      message: 'Vào đơn vị thất bại',
      description:
        'Email của Thầy/Cô chưa được cấp quyền vào đơn vị đã chọn. Thầy/Cô vui lòng liên hệ Cán bộ quản trị phụ trách của mình để được cấp quyền vào đơn vị. Mọi vướng mắc vui lòng liên hệ tổng đài CSKH 028 7300 3588 để được giải đáp. Trân trọng!',
    });
  }
}

function* signedIn(action) {
  if (action.payload) {
    const { uid, email, displayName, photoURL } = action.payload;

    const token = yield getAuth().currentUser?.getIdToken(true);
    const result = yield backendService.post('/api/auth', {
      email,
      uid,
      token,
      product: 'pcgd',
    });

    if (result.kind === 'ok') {
      const user = result.data;
      const userData = {
        email,
        uid,
        displayName,
        photoURL,
        ...user,
      };

      if (userData.activeUnit) {
        try {
          yield* getActiveUnit(userData.activeUnit, userData);
          initializeGA(userData.uid, userData.email, userData.activeUnit);
        } catch (error) {
          delete userData.activeUnit;
          yield put(userActions.fetchInformation(userData));
          notification.warning({
            message: 'Vào đơn vị thất bại',
            description: error.message,
          });
        }
      }
      yield put(catalogActions.getSystemCatalogs());
      yield put(userActions.fetchInformation(userData));
    } else {
      notification.warning({
        message: 'Đăng nhập thất bại',
        description: formatError(result),
      });
    }
  } else {
    yield put(userActions.fetchInformation(null));
  }
}

function* leave() {
  yield put(userActions.updateUserInformation({ activeUnit: '' }));
  window.location.reload();
}

function* signOut() {
  yield signOutFirebase(getAuth());
  backendService.post('/api/signedOut');
  window.location.reload();
}

export default function* saga() {
  yield all([
    takeLatest(userActions.signedIn, signedIn),
    takeLatest(userActions.signOut, signOut),
    takeLatest(userActions.leave, leave),
    takeLatest(userActions.joinUnit, joinUnit),
    takeLatest(userActions.updateUserInformation, updateUserInformation),
  ]);
}
