import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout } from 'antd';
import _ from 'lodash';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { pageView } from '../../../../utils/ga';
import useStyles from './styles';

const Paths = () => {
  const S = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const items = useMemo(() => {
    pageView(location.pathname);
    const paths = location.pathname.split('/');
    return _.uniqBy(
      _.compact(
        paths.map((page) => {
          switch (page) {
            case '':
              return {
                key: '',
                title: (
                  <>
                    <HomeOutlined />
                    <span>Trang chủ</span>
                  </>
                ),
                onClick: () => navigate(''),
              };
            case 'catalog':
              return {
                key: page,
                title: 'Danh mục',
              };
            case 'staffs':
              return {
                key: page,
                title: 'Thành viên',
              };
            case 'units':
              return {
                key: page,
                title: 'Đơn vị hành chính',
              };
            case 'schools':
              return {
                key: page,
                title: 'Trường',
              };
            case 'ethnics':
              return {
                key: page,
                title: 'Dân tộc',
              };
            case 'religions':
              return {
                key: page,
                title: 'Tôn giáo',
              };
            case 'dieutra':
              return {
                key: page,
                title: 'Điều tra',
              };
            case 'doituong':
              return {
                key: page,
                title: 'Đối tượng điều tra',
              };
            case 'traituyen':
              return {
                key: page,
                title: 'Đối tượng trái tuyến',
              };
            case 'analytics':
              return {
                key: page,
                title: 'Báo cáo thống kê',
              };
            case 'management':
              return {
                key: page,
                title: 'Quản trị',
              };
            case 'reportConfigs':
              return {
                key: page,
                title: 'Cấu hình báo cáo',
              };
            case 'sources':
              return {
                key: page,
                title: 'Kết nối',
              };
            case 'view':
              return {
                key: page,
                title: 'Tra cứu',
              };
            case 'reportManagement':
              return {
                key: page,
                title: 'Quản lý báo cáo',
              };
            case 'thuvienvanban':
              return {
                key: page,
                title: 'Thư viện văn bản',
              };
            default:
              return {
                key: page,
                title: '',
              };
          }
        })
      ),
      (e) => e.key
    );
  }, [location, navigate]);

  if (items.length) {
    return (
      <Layout.Header className={S.container}>
        <Breadcrumb items={items} />
      </Layout.Header>
    );
  }
  return null;
};

export default Paths;
