import { createSlice } from '../../../utils/@reduxjs/toolkit';

export const initialState = {
  data: {},
  handling: false,
  view: {},
  dynamicFilters: {},
  dynamicSorts: {},
  totalSubjects: 0,
};
const slice = createSlice({
  name: 'subjectStore',
  initialState,
  reducers: {
    fetchSubjects(state, action) {
      const { data } = action.payload;
      state.handling = false;
      state.data = {
        ...state.data,
        ...data,
      };
    },
    view(state) {
      state.handling = true;
    },
    fetchViewSubjects(state, action) {
      const { data, totalSubjects, subjectIds } = action.payload;
      state.handling = false;
      state.view = data;
      state.totalSubjects = totalSubjects;
      state.subjectIds = subjectIds;
    },
  },
});

export const { actions, name: key, reducer } = slice;
