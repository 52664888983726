import { Layout, Menu, Typography } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import {
  BsCardList,
  BsFileEarmarkBarGraph,
  BsFileEarmarkRuled,
  BsShieldShaded,
} from 'react-icons/bs';
import { FaCopy } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectUnitType } from '../../../catalogs/store/selectors';
import useStyles from './styles';

const { Text } = Typography;

const Sider = ({ openEditModal }) => {
  const S = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const unitType = useSelector(selectUnitType);

  const [collapsed, setCollapsed] = useState(false);

  const activeMenu = useMemo(() => {
    const splitPaths = _.compact(location.pathname.split('/'));
    if (!splitPaths.length) {
      return ['dieutra', 'phieudieutra'];
    }
    return _.compact(location.pathname.split('/'));
  }, [location.pathname]);

  const menuItems = useMemo(() => {
    const subCatalogs = [
      {
        key: 'staffs',
        label: 'Thành viên',
        onClick: () => navigate('/catalog/staffs'),
      },
      {
        key: 'units',
        label: 'Đơn vị hành chính',
        onClick: () => navigate('/catalog/units'),
      },
      {
        key: 'schools',
        label: 'Trường',
        onClick: () => navigate('/catalog/schools'),
      },
      {
        key: 'ethnics',
        label: 'Dân tộc',
        onClick: () => navigate('/catalog/ethnics'),
      },
      {
        key: 'religions',
        label: 'Tôn giáo',
        onClick: () => navigate('/catalog/religions'),
      },
      ...(unitType === 'tinh'
        ? [
            {
              label: 'Năm học',
              onClick: () => openEditModal('yearsConfig'),
            },
          ]
        : []),
    ];

    const items = [
      {
        key: 'catalog',
        label: <Text className={S.menuTitle}>Danh mục</Text>,
        icon: <BsCardList />,
        children: subCatalogs,
      },
      {
        key: 'dieutra',
        label: <Text className={S.menuTitle}>Điều tra</Text>,
        icon: <BsFileEarmarkRuled />,
        children: [
          {
            key: 'phieudieutra',
            label: 'Phiếu điều tra',
            onClick: () => navigate('/'),
          },
          {
            key: 'doituong',
            label: 'Đối tượng điều tra',
            onClick: () => navigate('/dieutra/doituong'),
          },
          {
            key: 'traituyen',
            label: 'Đối tượng trái tuyến',
            onClick: () => navigate('/dieutra/traituyen'),
          },
        ],
      },
      {
        key: 'analytics',
        label: <Text className={S.menuTitle}>Báo cáo thống kê</Text>,
        icon: <BsFileEarmarkBarGraph />,
        children: [
          {
            key: 'view',
            label: 'Tra cứu báo cáo',
            onClick: () => navigate('/analytics/view'),
          },
          {
            key: 'reportManagement',
            label: 'Quản lý báo cáo',
            onClick: () => navigate('/analytics/reportManagement'),
          },
        ],
      },
    ];

    if (unitType === 'tinh') {
      const subManagements = [
        {
          key: 'reportConfigs',
          label: 'Cấu hình báo cáo',
          onClick: () => navigate('/management/reportConfigs'),
        },
        {
          key: 'sources',
          label: 'Kết nối',
          onClick: () => navigate('/management/sources'),
        },
      ];
      items.push({
        key: 'management',
        label: <Text className={S.menuTitle}>Quản trị</Text>,
        icon: <BsShieldShaded />,
        children: subManagements,
      });
    }
    items.push({
      key: 'thuvienvanban',
      label: <Text className={S.menuTitle}>Thư viện văn bản</Text>,
      icon: <FaCopy />,
      onClick: () => navigate('/thuvienvanban'),
    });
    return items;
  }, [navigate, unitType, S.menuTitle, openEditModal]);

  return (
    <Layout.Sider
      className={S.container}
      breakpoint="xl"
      width={210}
      collapsible
      collapsed={collapsed}
      onCollapse={(val) => setCollapsed(val)}
      collapsedWidth={60}
      style={{ height: window.innerHeight - 90 }}
    >
      <Menu selectable={false} selectedKeys={activeMenu} items={menuItems} mode="inline" />
    </Layout.Sider>
  );
};

Sider.propTypes = {
  openEditModal: PropTypes.func.isRequired,
};

export default Sider;
