import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { ACTIVE_YEAR } from '../../../configs';
import { initialState } from './reducer';

const selectDomain = (state) => state?.catalogStore || initialState;

export const selectCities = createSelector([selectDomain], (state) => state.system.cities?.data);
export const selectCitiesHandling = createSelector(
  [selectDomain],
  (state) => state.system.cities?.handling
);
export const selectEthnics = createSelector([selectDomain], (state) => state.system.ethnics?.data);
export const selectReligions = createSelector(
  [selectDomain],
  (state) => state.system.religions?.data
);
export const selectAnalyticTypes = createSelector(
  [selectDomain],
  (state) => state.system.analyticTypes?.data
);

export const selectAnalyticTypesWithConfigs = createSelector([selectDomain], (state) => {
  const reportConfigs = state.unit.data?.configs?.reports || [];
  const analyticTypes = state.system.analyticTypes?.data || {};
  if (_.size(reportConfigs)) {
    const analyticTypesFilters = {};
    _.forEach(analyticTypes, (analytics, schoolType) => {
      analyticTypesFilters[schoolType] = _.filter(analytics, (analytic) =>
        _.includes(reportConfigs, analytic.value)
      );
    });
    return analyticTypesFilters;
  }
  return analyticTypes;
});

export const selectAnalyticConfigs = createSelector(
  [selectDomain],
  (state) => state.system.analyticConfigs?.data
);
export const selectSchools = createSelector([selectDomain], (state) => state.system.schools?.data);
export const selectSchoolsHandling = createSelector(
  [selectDomain],
  (state) => state.system.schools?.handling
);
export const selectVillages = createSelector(
  [selectDomain],
  (state) => state.system.villages?.data
);
export const selectVillagesHandling = createSelector(
  [selectDomain],
  (state) => state.system.villages?.handling
);

export const selectProductGuide = createSelector(
  [selectDomain],
  (state) => state.system?.guide?.data
);

export const selectCatalogHandling = createSelector([selectDomain], (state) => state.unit.handling);

export const selectCatalogReady = createSelector([selectDomain], (state) =>
  Boolean(state.unit.data)
);

export const selectUnitId = createSelector(
  [selectDomain],
  (state) => state.unit.data?.activeUnit?.id
);

export const selectUnitType = createSelector(
  [selectDomain],
  (state) => state.unit.data?.activeUnit?.type || 'phuong'
);

export const selectActiveUnit = createSelector(
  [selectDomain],
  (state) => state.unit.data?.activeUnit
);

export const selectReportConfigs = createSelector(
  [selectDomain],
  (state) => state.unit.data?.configs?.province?.reports || []
);

export const selectConfigs = createSelector([selectDomain], (state) => state.unit.data?.configs);

export const selectDocuments = createSelector(
  [selectDomain],
  (state) => state.unit.data?.configs?.province?.documents || []
);

export const selectSchoolYear = createSelector(
  [selectDomain],
  (state) => state.unit.data?.configs?.province?.year || ACTIVE_YEAR
);
