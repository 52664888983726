import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    background: 'none !important',
    overflow: 'auto',
    paddingTop: 50,
    '& ul': {
      background: 'transparent',
      borderInlineEnd: 'none !important',
    },
    '& .ant-layout-sider-children': {
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-layout-sider-trigger': {
      marginBottom: 40,
      background: 'transparent',
      color: '#1890ff',
      borderBottom: '1px solid #f0f0f0',
    },
  },
  logo: {
    verticalAlign: 'middle !important',
  },
  menuTitle: {
    fontWeight: '500',
    color: 'unset',
  },
});
