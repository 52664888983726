import { Button, Popconfirm, theme } from 'antd';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';

const EditButton = ({ confirmation, onConfirm, onClick }) => {
  const { token } = theme.useToken();
  const button = (
    <Button
      shape="circle"
      type="ghost"
      size="small"
      onClick={onClick}
      icon={<MdEdit color={token.colorPrimary} />}
    />
  );
  if (confirmation) {
    return (
      <Popconfirm title={confirmation} onConfirm={onConfirm}>
        {button}
      </Popconfirm>
    );
  }
  return button;
};

EditButton.defaultProps = {
  confirmation: undefined,
  onConfirm: undefined,
  onClick: undefined,
};

EditButton.propTypes = {
  confirmation: PropTypes.string,
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
};

export default EditButton;
