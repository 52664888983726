import { notification } from 'antd';
import _ from 'lodash';
import ReactGA from 'react-ga4';

import packageJson from '../../package.json';

const dev = process.env.REACT_APP_STAGE !== 'production';

export const initializeGA = (uid, email, unitId) => {
  if (dev) return;
  ReactGA.initialize([
    {
      trackingId: 'G-C0FNRF6NTR',
      gaOptions: {
        clientId: uid,
        userId: uid,
        appId: 'elibot-release.firebaseapp.com',
        appVersion: packageJson.version,
        name: 'main',
      },
    },
    {
      trackingId: 'G-VEKCMBX9PN',
      gaOptions: {
        clientId: uid,
        userId: uid,
        name: 'log',
      },
    },
    {
      trackingId: 'G-0KFL4E6B0Y',
      gaOptions: {
        clientId: _.replace(email, /@/g, '_'),
        userId: _.replace(email, /@/g, '_'),
        name: 'emaillog',
      },
    },
    {
      trackingId: 'G-5ENKWBHHZX',
      gaOptions: {
        clientId: unitId,
        userId: unitId,
        name: 'unitlog',
      },
    },
  ]);
};

export const pageView = (page) => {
  if (dev) {
    return;
  }
  ReactGA.send({ hitType: 'pageview', page });
};

export const writeLog = (category, action, label, value = 1) => {
  if (dev) {
    return;
  }

  const sendLog = (logServer) =>
    ReactGA.ga(
      logServer,
      'event',
      _.replace(`${category}`, /@/g, '_'),
      _.replace(`${action}`, /@/g, '_'),
      _.replace(`${label}`, /@/g, '_'),
      value
    );
  sendLog('send');
};

export const exception = (msg) => {
  // eslint-disable-next-line no-console
  console.log('Exception: ', msg);
};

export const errorLog = (category, action, label, error) => {
  const { message, data } = error || {};
  const msg = message || data || error;
  exception([action, label, msg].join(', '));
  writeLog(category, `${action} - Lỗi`, label);
};

export const errorHandler = (title, error, onSuccess) => {
  if (onSuccess) onSuccess();
  const { message, data } = error || {};
  const msg = message || data || error;
  exception(msg);
  notification.error({ message: title, description: msg });
};

export const successHandler = (title, message, onSuccess) => {
  if (onSuccess) onSuccess();
  notification.success({ message: title, description: message });
};
