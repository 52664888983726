import { notification } from 'antd';
import _ from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { ACTIVE_YEAR } from '../../../configs';
import { backendService } from '../../../services';
import formatError from '../../../utils/formatError';
import { selectUserInformation } from '../../user/store/selectors';
import { actions as catalogActions } from './reducer';
import { selectActiveUnit, selectCities, selectConfigs, selectUnitId } from './selectors';

function* loadLiveSupport() {
  const cities = yield select(selectCities);
  const userInformation = yield select(selectUserInformation);
  const activeUnit = yield select(selectActiveUnit);

  window?.fcWidget?.init({
    token: 'a1e32696-ff15-47e3-a3ec-44c01ddf8f0c',
    host: 'https://wchat.freshchat.com',
    locale: 'vi',
    siteId: 'PCGD',
    tags: ['pcgd'],
    firstName: 'Thầy/Cô',
    lastName: userInformation.displayName,
    email: userInformation.email,
    faqTags: {
      tags: ['pcgd'],
      filterType: 'category',
    },
    config: {
      showFAQOnOpen: false,
      headerProperty: {
        hideChatButton: true,
        appName: 'PCGD',
      },
      content: {
        placeholders: {
          search_field: 'Tìm kiếm',
          reply_field: 'Trả lời',
          csat_reply: 'Nhập nội dung đánh giá...',
        },
        actions: {
          csat_yes: 'Có',
          csat_no: 'Không',
          push_notify_yes: 'Có',
          push_notify_no: 'Không',
          tab_faq: 'Câu hỏi thường gặp',
          tab_chat: 'Hỗ trợ',
          csat_submit: 'Gửi',
        },
        headers: {
          chat: 'Hỗ trợ trực tuyến',
          chat_help: 'Xin cám ơn thầy/cô đã sử dụng hệ thống PCGD',
          faq: 'Các câu hỏi thường gặp',
          faq_help: 'Thầy/Cô có thể tìm kiếm theo nội dung',
          faq_not_available: 'Không tìm thấy',
          faq_search_not_available: 'Không tìm thấy tài liệu nào có nội dung {{query}}',
          faq_useful: 'Tài liệu này có giúp ích gì cho thầy/cô không?',
          faq_thankyou: 'Cám ơn phản hồi của thầy/cô',
          faq_message_us: 'Góp ý',
          push_notification: 'Thầy/Cô có đồng ý hiện thông báo khi có tin nhắn đến?',
          csat_question: 'Câu hỏi của thầy/cô có được trả lời không??',
          csat_yes_question: 'Thầy/cô đánh giá như thế nào về thái độ chăm sóc?',
          csat_no_question: 'Công ty có thể cải thiện gì để hỗ trợ tốt thêm không?',
          csat_thankyou: 'Cám ơn đánh giá của thầy/cô',
          csat_rate_here: 'Gửi đánh giá',
          channel_response: {
            offline:
              'Hiện không có nhân viên nào đang trực tổng đài. Thầy/Cô vui lòng để lại tin nhắn tại đây.',
            online: {
              minutes: {
                one: 'Thầy/Cô vui lòng đợi khoảng {!time!} phút',
                more: 'Thông thường sẽ mất khoảng {!time!} phút',
              },
              hours: {
                one: 'Thầy/Cô vui lòng đợi khoảng 1 tiếng',
                more: 'Thông thường sẽ mất khoảng {!time!} tiếng',
              },
            },
          },
        },
      },
    },
  });

  const gender = userInformation.male ? 'Thầy' : 'Cô';

  const province = cities?.[activeUnit?.province ?? ''];
  const district = province?.districts?.[activeUnit?.district ?? ''];
  const ward = province?.districts?.[activeUnit?.district ?? '']?.wards?.[activeUnit?.ward ?? ''];

  window.fcWidget.user.setProperties({
    firstName: gender,
    lastName: userInformation.displayName,
    email: userInformation.email,
    unit_name: activeUnit?.name ?? '',
    unit_id: activeUnit?.id ?? '',
    address: '',
    provinceName: province?.name ?? 'Không tìm thấy',
    districtName: district?.name ?? 'Không tìm thấy',
    wardName: ward?.name ?? 'Không tìm thấy',
    user_uid: userInformation.uid,
  });
}

function* getVillages(action) {
  const { year } = action.payload;
  try {
    const villages = yield backendService.post('/api/getVillages', { year });
    if (villages.kind === 'ok') {
      const data = {};
      _.forEach(villages?.data, (village) => {
        const newVillage = _.cloneDeep(village);
        delete newVillage._id;
        delete newVillage.ward;
        delete newVillage.year;
        data[village.ward] = newVillage;
      });
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'villages', data, year }));
    } else {
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'villages', data: {}, year }));
    }
  } catch (ex) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'villages' }));
    notification.warning({
      message: 'Tải dữ liệu thất bại',
      description: 'Thôn xóm',
    });
  }
}

function* cacheSchools(action) {
  const { year } = action.payload;
  try {
    const cached = yield backendService.post('/api/redis', {
      command: 'set',
      key: `schools.${year}`,
      conditions: { year },
    });
    if (cached.kind === 'ok') {
      yield put(catalogActions.getSchools({ year }));
    } else {
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'schools', year, data: {} }));
      notification.warning({
        message: 'Cập nhật thất bại',
        description: formatError(cached),
      });
    }
  } catch (error) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'schools', year, data: {} }));
    notification.warning({
      description: formatError(error),
    });
  }
}

function* getSchools(action) {
  const { year } = action.payload;
  try {
    const result = yield backendService.post('/api/redis', {
      command: 'get',
      key: `schools.${year}`,
    });
    if (result.kind === 'ok') {
      const schools = result.data;
      yield put(
        catalogActions.fetchSystemCatalog({
          catalog: 'schools',
          data: schools ? JSON.parse(schools) : {},
          year,
        })
      );
    } else {
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'schools', data: {}, year }));
      notification.warning({
        message: 'Tải dữ liệu thất bại',
        description: formatError(result),
      });
    }
  } catch ({ message }) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'schools', data: {}, year }));
    notification.warning({
      message: 'Tải dữ liệu thất bại',
      description: message,
    });
  }
}
function* getConfigs(action) {
  const { province, district, ward } = action.payload;
  try {
    const configs = yield backendService.post('/api/getConfigs', { province, district, ward });
    if (configs.kind === 'ok') {
      const year = configs?.data?.province?.year || ACTIVE_YEAR;
      yield put(catalogActions.fetchUnitCatalog({ catalog: 'configs', data: configs?.data || {} }));
      yield put(catalogActions.getVillages({ year }));
      yield put(catalogActions.getSchools({ year }));
    } else {
      yield put(catalogActions.fetchUnitCatalog({ catalog: 'configs', data: {} }));
    }
  } catch (ex) {
    yield put(catalogActions.fetchUnitCatalog({ catalog: 'configs' }));
    notification.warning({
      message: 'Tải dữ liệu thất bại',
      description: 'Cấu hình',
    });
  }
}

function* updateConfigs(action) {
  const { field, data, logChanges, logType } = action.payload;
  const unitId = yield select(selectUnitId);
  const configs = yield select(selectConfigs);
  try {
    const result = yield backendService.post('/api/updateConfigs', {
      unitId,
      field,
      data,
      logChanges,
      logType,
    });
    if (result.kind === 'ok') {
      const newConfigs = _.cloneDeep(configs);
      newConfigs.province[field] = data;
      yield put(catalogActions.fetchUnitCatalog({ catalog: 'configs', data: newConfigs }));
      notification.success({
        message: 'Cập nhật thành công',
      });
    } else {
      notification.warning({
        message: 'Cập nhật thất bại',
      });
      yield put(catalogActions.updateCatalog());
    }
  } catch (ex) {
    yield put(catalogActions.updateCatalog());
    notification.warning({
      message: 'Cập nhật dữ liệu thất bại',
      description: 'Cấu hình',
    });
  }
}

function* getSystemCatalogs() {
  const error = [];
  try {
    const cities = yield backendService.post('/api/cities');
    if (cities.kind === 'ok') {
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'cities', data: cities.data }));
    } else {
      error.push(formatError(cities));
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'cities', data: {} }));
    }
  } catch (ex) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'cities' }));
    error.push(formatError(ex));
  }

  try {
    const catalogs = yield backendService.post('/api/getInitCatalogs');
    if (catalogs.kind === 'ok') {
      if (catalogs?.data?.ethnics) {
        yield put(
          catalogActions.fetchSystemCatalog({
            catalog: 'ethnics',
            data: JSON.parse(catalogs.data.ethnics),
          })
        );
      } else {
        yield put(catalogActions.fetchSystemCatalog({ catalog: 'ethnics', data: {} }));
      }
      if (catalogs?.data?.religions) {
        yield put(
          catalogActions.fetchSystemCatalog({
            catalog: 'religions',
            data: JSON.parse(catalogs.data.religions),
          })
        );
      } else {
        yield put(catalogActions.fetchSystemCatalog({ catalog: 'religions', data: {} }));
      }
    } else {
      error.push(formatError(catalogs));
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'ethnics', data: {} }));
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'religions', data: {} }));
    }
  } catch (ex) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'ethnics' }));
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'religions' }));
    error.push(formatError(ex));
  }

  try {
    const analyticTypes = yield backendService.post('/api/analyticTypes');
    if (analyticTypes.kind === 'ok') {
      yield put(
        catalogActions.fetchSystemCatalog({ catalog: 'analyticTypes', data: analyticTypes.data })
      );
    } else {
      error.push(formatError(analyticTypes));
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'analyticTypes', data: {} }));
    }
  } catch (ex) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'analyticTypes' }));
    error.push(formatError(ex));
  }

  try {
    const analyticConfigs = yield backendService.post('/api/analyticConfigs');
    if (analyticConfigs.kind === 'ok') {
      yield put(
        catalogActions.fetchSystemCatalog({
          catalog: 'analyticConfigs',
          data: analyticConfigs.data,
        })
      );
    } else {
      error.push(formatError(analyticConfigs));
      yield put(catalogActions.fetchSystemCatalog({ catalog: 'analyticConfigs', data: {} }));
    }
  } catch (ex) {
    yield put(catalogActions.fetchSystemCatalog({ catalog: 'analyticConfigs' }));
    error.push(formatError(ex));
  }

  yield loadLiveSupport();

  if (error.length) {
    notification.warning({
      message: 'Tải dữ liệu thất bại',
      description: error.join('; '),
    });
  }
}

function* updateUnitInformation(action) {
  const userInfo = yield select(selectUserInformation);
  const { logChanges, members, ...unit } = action.payload;
  const unitData = unit;
  if (members) {
    const arrMembers = Object.entries(members).map(([email, memberData]) => ({
      email,
      ...memberData,
    }));
    unitData.members = arrMembers;
  }

  const result = yield backendService.graphql(
    '/database/units',
    'updateUnit',
    `
      mutation updateUnit($unit: String!, $data: UnitInput, $logChanges: String) {
        updateUnit(unit: $unit, data: $data, logChanges: $logChanges)
      }
    `,
    {
      unit: userInfo.activeUnit,
      data: unitData,
      logChanges,
    }
  );

  if (result.kind === 'ok') {
    if (logChanges) {
      delete action.payload.logChanges;
    }
    yield put(
      catalogActions.updateCatalog({
        catalog: 'activeUnit',
        items: Object.entries(action.payload).map(([key, data]) => ({ id: key, data })),
      })
    );
    notification.success({
      message: 'Lưu thành công',
    });
    return;
  }
  notification.warning({
    message: 'Cập nhật thất bại',
    description: formatError(result),
  });
  yield put(
    catalogActions.updateCatalog({
      catalog: 'activeUnit',
      items: [],
    })
  );
}
export default function* saga() {
  yield all([
    takeLatest(catalogActions.getSystemCatalogs, getSystemCatalogs),
    takeLatest(catalogActions.updateUnitInformation, updateUnitInformation),
    takeLatest(catalogActions.getVillages, getVillages),
    takeLatest(catalogActions.getConfigs, getConfigs),
    takeLatest(catalogActions.updateConfigs, updateConfigs),
    takeLatest(catalogActions.getSchools, getSchools),
    takeLatest(catalogActions.cacheSchools, cacheSchools),
  ]);
}
