import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: () => ({
    background: 'transparent',
    marginTop: 50,
    height: 45,
    paddingInline: 15,
    display: 'flex',
    alignItems: 'center',
  }),
});
