import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: () => ({
    backgroundColor: '#fff',
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 999,
    height: 48,
    paddingInline: 5,
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
    '& ul': {
      background: 'transparent',
      borderInlineEnd: 'none !important',
    },
  }),
  logo: {
    verticalAlign: 'middle !important',
  },
  leftHeader: {
    marginLeft: 20,
    lineHeight: 0,
  },
});
