import _ from 'lodash';

export const unitTypesOptions = [
  {
    value: 'tinh',
    label: 'Sở Giáo dục',
  },
  {
    value: 'quan',
    label: 'Phòng Giáo dục',
  },
  {
    value: 'phuong',
    label: 'Phường/Xã',
  },
];

export const schoolYears = _.map(_.range(2015, 2030), (year) => ({
  value: year,
  label: `${year}-${year + 1}`,
}));

export const schoolTypes = {
  thpt: {
    name: 'THPT',
  },
  thpt_botuc: {
    name: 'THPT bổ túc',
  },
  daynghe: {
    name: 'Trường dạy nghề',
  },
  thcn: {
    name: 'Trung học chuyên nghiệp',
  },
  mn: {
    name: 'Mầm non',
  },
  th: {
    name: 'Tiểu học',
  },
  thcs: {
    name: 'THCS',
  },
  thcs_botuc: {
    name: 'THCS bổ túc',
  },
  cl: {
    name: 'Công lập',
  },
  bt: {
    name: 'Bổ túc',
  },
  lc: {
    name: 'Liên cấp',
  },
  lc_gdtx: {
    name: 'Liên cấp GDTX',
  },
  nl: {
    name: 'Nhóm lớp',
  },
  csgd: {
    name: 'Cơ sở giáo dục',
  },
};

export const schoolLevels = {
  mn: {
    name: 'Mầm non',
  },
  th: {
    name: 'Tiểu học',
  },
  mn_th: {
    name: 'Mầm non + Tiểu học',
  },
  mn_th_thcs: {
    name: 'MN + TH + THCS',
  },
  mn_th_thcs_thpt: {
    name: 'MN + TH + THCS + THPT',
  },
  thcs: {
    name: 'THCS',
  },
  th_thcs: {
    name: 'Tiểu học + THCS',
  },
  thpt: {
    name: 'THPT',
  },
  thcs_thpt: {
    name: 'THCS + THPT',
  },
  th_thcs_thpt: {
    name: 'Tiểu học + THCS + THPT',
  },
  thcs_thpt_botuc: {
    name: 'THCS bổ túc + THPT bổ túc',
  },
  daynghe: {
    name: 'Trường dạy nghề',
  },
  thcn: {
    name: 'Trung học chuyên nghiệp',
  },
  cd: {
    name: 'Cao đẳng',
  },
  dh: {
    name: 'Đại học',
  },
};

export const headerSchoolOptions = [
  'mã trường',
  'tên trường',
  'loại hình trường',
  'bậc học',
  'tên phường xã',
  'tên quận huyện',
  'tên tỉnh',
];

export const priorities = [
  {
    key: 'conlietsi',
    value: 'conlietsi',
    text: 'Con liệt sĩ',
  },
  {
    key: 'thuongbinh14',
    value: 'thuongbinh14',
    text: 'Con thương binh nặng 1/4',
  },
  {
    key: 'thuongbinh24',
    value: 'thuongbinh24',
    text: 'Con thương binh 2/4',
  },
  {
    key: 'thuongbinh34',
    value: 'thuongbinh34',
    text: 'Con thương binh 3/4',
  },
  {
    key: 'thuongbinh44',
    value: 'thuongbinh44',
    text: 'Con thương binh 4/4',
  },
  {
    key: 'mocoi',
    value: 'mocoi',
    text: 'Mồ côi cha mẹ',
  },
  {
    key: 'hongheo',
    value: 'hongheo',
    text: 'Hộ nghèo',
  },
  {
    key: 'hocanngheo',
    value: 'hocanngheo',
    text: 'Hộ cận nghèo',
  },
  {
    key: 'kinhvungcao',
    value: 'kinhvungcao',
    text: 'Kinh vùng cao',
  },
  {
    key: 'dtvungcao',
    value: 'dtvungcao',
    text: 'Con dân tộc vùng cao',
  },
  {
    key: 'dtvungthap',
    value: 'dtvungthap',
    text: 'Con dân tộc vùng thấp',
  },
  {
    key: 'docdacam',
    value: 'docdacam',
    text: 'Bị chất độc da cam',
  },
  {
    key: 'benhbinh14',
    value: 'benhbinh14',
    text: 'Con bệnh binh 1/4',
  },
  {
    key: 'benhbinh24',
    value: 'benhbinh24',
    text: 'Con bệnh binh 2/4',
  },
  {
    key: 'benhbinh34',
    value: 'benhbinh34',
    text: 'Con bệnh binh 3/4',
  },
  {
    key: 'benhbinh44',
    value: 'benhbinh44',
    text: 'Con bệnh binh 4/4',
  },
  {
    key: 'doidoixuatngu',
    value: 'doidoixuatngu',
    text: 'Bộ đội xuất ngũ',
  },
];

export const residentOptions = [
  {
    key: 'thuongtru',
    text: 'Thường trú',
    value: 'thuongtru',
  },
  {
    key: 'tamtru',
    text: 'Tạm trú',
    value: 'tamtru',
  },
];

export const residentObject = {};
residentOptions.forEach((item) => {
  residentObject[item.value] = item.text;
});

export const residentStatusOptions = [
  {
    key: 'vang',
    text: 'Vắng',
    value: 'vang',
  },
  {
    key: 'luutru',
    text: 'Lưu Trú',
    value: 'luutru',
  },
];

export const residentStatusObject = {};
residentStatusOptions.forEach((item) => {
  residentStatusObject[item.value] = item.text;
});

export const grades = [
  {
    key: '3_12thang',
    value: '3_12thang',
    text: '3-12 tháng',
    levelup: '13_24thang',
  },
  {
    key: '13_24thang',
    value: '13_24thang',
    text: '13-24 tháng',
    levelup: '25_36thang',
  },
  {
    key: '25_36thang',
    value: '25_36thang',
    text: '25-36 tháng',
    levelup: '3_4tuoi',
  },
  {
    key: '3_4tuoi',
    value: '3_4tuoi',
    text: '3-4 tuổi',
    levelup: '4_5tuoi',
  },
  {
    key: '4_5tuoi',
    value: '4_5tuoi',
    text: '4-5 tuổi',
    levelup: '5_6tuoi',
  },
  {
    key: '5_6tuoi',
    value: '5_6tuoi',
    text: '5-6 tuổi',
    levelup: 'khoi1',
  },
  {
    key: 'khoi1',
    value: 'khoi1',
    text: '1',
    levelup: 'khoi2',
  },
  {
    key: 'khoi2',
    value: 'khoi2',
    text: '2',
    levelup: 'khoi3',
  },
  {
    key: 'khoi3',
    value: 'khoi3',
    text: '3',
    levelup: 'khoi4',
  },
  {
    key: 'khoi4',
    value: 'khoi4',
    text: '4',
    levelup: 'khoi5',
  },
  {
    key: 'khoi5',
    value: 'khoi5',
    text: '5',
    levelup: 'khoi6',
  },
  {
    key: 'khoi6',
    value: 'khoi6',
    text: '6',
    levelup: 'khoi7',
  },
  {
    key: 'khoi7',
    value: 'khoi7',
    text: '7',
    levelup: 'khoi8',
  },
  {
    key: 'khoi8',
    value: 'khoi8',
    text: '8',
    levelup: 'khoi9',
  },
  {
    key: 'khoi9',
    value: 'khoi9',
    text: '9',
    levelup: 'khoi10',
  },
  {
    key: 'khoi10',
    value: 'khoi10',
    text: '10',
    levelup: 'khoi11',
  },
  {
    key: 'khoi11',
    value: 'khoi11',
    text: '11',
    levelup: 'khoi12',
  },
  {
    key: 'khoi12',
    value: 'khoi12',
    text: '12',
    levelup: 'sv1',
  },
  {
    key: 'tdn1',
    value: 'tdn1',
    text: 'TDN1',
    levelup: 'tdn2',
  },
  {
    key: 'tdn2',
    value: 'tdn2',
    text: 'TDN2',
    levelup: 'tdn3',
  },
  {
    key: 'tdn3',
    value: 'tdn3',
    text: 'TDN3',
    levelup: 'tdn35',
  },
  {
    key: 'tdn35',
    value: 'tdn35',
    text: 'TDN35',
    levelup: 'tdn4',
  },
  {
    key: 'tdn4',
    value: 'tdn4',
    text: 'TDN4',
    levelup: 'thcn1',
  },
  {
    key: 'thcn1',
    value: 'thcn1',
    text: 'THCN1',
    levelup: 'thcn2',
  },
  {
    key: 'thcn2',
    value: 'thcn2',
    text: 'THCN2',
    levelup: 'thcn3',
  },
  {
    key: 'thcn3',
    value: 'thcn3',
    text: 'THCN3',
    levelup: 'thcn35',
  },
  {
    key: 'thcn35',
    value: 'thcn35',
    text: 'THCN35',
    levelup: 'thcn4',
  },
  {
    key: 'thcn4',
    value: 'thcn4',
    text: 'THCN4',
    levelup: 'thcn4',
  },
  {
    key: 'sv1',
    value: 'sv1',
    text: 'SV1',
    levelup: 'sv2',
  },
  {
    key: 'sv2',
    value: 'sv2',
    text: 'SV2',
    levelup: 'sv3',
  },
  {
    key: 'sv3',
    value: 'sv3',
    text: 'SV3',
    levelup: 'sv4',
  },
  {
    key: 'sv4',
    value: 'sv4',
    text: 'SV4',
    levelup: 'sv5',
  },
  {
    key: 'sv5',
    value: 'sv5',
    text: 'SV5',
    levelup: 'sv6',
  },
  {
    key: 'sv6',
    value: 'sv6',
    text: 'SV6',
    levelup: 'sv7',
  },
  {
    key: 'sv7',
    value: 'sv7',
    text: 'SV7',
    levelup: 'sv7',
  },
];

export const gradesObject = {};
grades.forEach((item) => {
  gradesObject[item.value] = item.text;
});

export const levels = [
  {
    key: 'mn',
    value: 'mn',
    text: 'MN',
  },
  {
    key: 'th',
    value: 'th',
    text: 'TH',
  },
  {
    key: 'thcs',
    value: 'thcs',
    text: 'THCS',
  },
  {
    key: 'thpt',
    value: 'thpt',
    text: 'THPT',
  },
];

export const levelsObject = {};
levels.forEach((item) => {
  levelsObject[item.value] = item.text;
});

export const careerLevels = [
  {
    key: 'tdn',
    value: 'tdn',
    text: 'TDN',
  },
  {
    key: 'thcn',
    value: 'thcn',
    text: 'THCN',
  },
  {
    key: 'cd',
    value: 'cd',
    text: 'Cao Đẳng',
  },
  {
    key: 'dh',
    value: 'dh',
    text: 'Đại Học',
  },
  {
    key: 'thacsi',
    value: 'thacsi',
    text: 'Thạc Sĩ',
  },
  {
    key: 'tiensi',
    value: 'tiensi',
    text: 'Tiến Sĩ',
  },
];

export const careerLevelsObject = {};
careerLevels.forEach((item) => {
  careerLevelsObject[item.value] = item.text;
});

export const classes = [
  {
    key: '3_12thang',
    value: '3_12thang',
    text: '3-12 tháng',
  },
  {
    key: '13_24thang',
    value: '13_24thang',
    text: '13-24 tháng',
  },
  {
    key: '25_36thang',
    value: '25_36thang',
    text: '25-36 tháng',
  },
  {
    key: '3_4tuoi',
    value: '3_4tuoi',
    text: '3-4 tuổi',
  },
  {
    key: '4_5tuoi',
    value: '4_5tuoi',
    text: '4-5 tuổi',
  },
  {
    key: '5_6tuoi',
    value: '5_6tuoi',
    text: '5-6 tuổi',
  },
  {
    key: 'khoi1',
    value: 'khoi1',
    text: '1',
  },
  {
    key: 'khoi2',
    value: 'khoi2',
    text: '2',
  },
  {
    key: 'khoi3',
    value: 'khoi3',
    text: '3',
  },
  {
    key: 'khoi4',
    value: 'khoi4',
    text: '4',
  },
  {
    key: 'khoi5',
    value: 'khoi5',
    text: '5',
  },
  {
    key: 'khoi6',
    value: 'khoi6',
    text: '6',
  },
  {
    key: 'khoi7',
    value: 'khoi7',
    text: '7',
  },
  {
    key: 'khoi8',
    value: 'khoi8',
    text: '8',
  },
  {
    key: 'khoi9',
    value: 'khoi9',
    text: '9',
  },
  {
    key: 'khoi10',
    value: 'khoi10',
    text: '10',
  },
  {
    key: 'khoi11',
    value: 'khoi11',
    text: '11',
  },
  {
    key: 'khoi12',
    value: 'khoi12',
    text: '12',
  },
];

export const classesObject = {};
classes.forEach((item) => {
  classesObject[item.value] = item.text;
});

export const illiteracyFinishOptions = [
  {
    key: 'lop3',
    text: '3',
    value: 'lop3',
  },
  {
    key: 'lop5',
    text: '5',
    value: 'lop5',
  },
];

export const illiteracyFinishObject = {};
illiteracyFinishOptions.forEach((item) => {
  illiteracyFinishObject[item.value] = item.text;
});

export const illiteracyLevelOptions = [
  {
    key: 'muc1',
    text: '1',
    value: 'muc1',
  },
  {
    key: 'muc2',
    text: '2',
    value: 'muc2',
  },
];

export const illiteracyLevelObject = {};
illiteracyLevelOptions.forEach((level) => {
  illiteracyLevelObject[level.value] = level.text;
});

export const specialSituationOptions = [
  {
    value: 'moveAway',
    text: 'Chuyển đi',
    key: 'moveAway',
  },
  {
    value: 'moveIn',
    text: 'Chuyển đến',
    key: 'moveIn',
  },
  {
    value: 'death',
    text: 'Chết',
    key: 'death',
  },
];

export const specialSituationObject = {};
specialSituationOptions.forEach((level) => {
  specialSituationObject[level.value] = level.text;
});

export const requiredSurveyFields = [
  {
    field: 'firstNameSubject',
    text: 'Thiếu Họ đệm (cột 1)',
  },
  {
    field: 'lastNameSubject',
    text: 'Thiếu Tên (cột 2)',
  },
  {
    field: 'yearOlds',
    text: 'Thiếu Năm sinh (cột 5)',
  },
  {
    field: 'firstNameSurvey',
    text: 'Thiếu Họ đệm chủ hộ (cột 11)',
  },
  {
    field: 'lastNameSurvey',
    text: 'Thiếu Tên chủ hộ (cột 12)',
  },
  {
    field: 'numberSurvey',
    text: 'Thiếu Số phiếu (cột 14)',
  },
  {
    field: 'resident',
    text: 'Thiếu Diện cư trú (cột 15)',
  },
];

export const requiredCrossAreaFields = [
  {
    field: 'firstName',
    text: 'Thiếu Họ đệm (cột 2)',
  },
  {
    field: 'lastName',
    text: 'Thiếu Tên (cột 3)',
  },
  {
    field: 'yearOlds',
    text: 'Thiếu Năm sinh (cột 6)',
  },
  {
    field: 'livingProvince',
    text: 'Tỉnh/Thành đang ở (cột 12)',
  },
  {
    field: 'livingDistrict',
    text: 'Quận/Huyện đang ở (cột 13)',
  },
  {
    field: 'livingWard',
    text: 'Phường/Xã đang ở (cột 14)',
  },
  {
    field: 'grade',
    text: 'Khối học (cột 15)',
  },
  {
    field: 'codeSchoolYear',
    text: 'Mã trường (cột 19)',
  },
];

export const regexSurveys = {
  yearOlds: 'Năm sinh không hợp lệ (cột 5)',
  numberSurvey:
    'bị trùng. Đề nghị kiểm tra lại thông tin của 4 cột 11, 12, 13, 14 trong file excel phải giống hệt nhau trong cùng 1 số phiếu.',
  validDate: 'Ngày, tháng, năm sinh (cột 3, 4, 5) không hợp lệ, Thầy/Cô vui lòng chỉnh sửa lại',
  validNumberSurvey:
    'không hợp lệ, Số phiếu chỉ được phép nhập Chữ, Số, Dấu - và Dấu _ Thầy/Cô vui lòng chỉnh sửa lại',
};

export const listGradeSchool = [
  'khoi1',
  'khoi2',
  'khoi3',
  'khoi4',
  'khoi5',
  'khoi6',
  'khoi7',
  'khoi8',
  'khoi9',
  'khoi10',
  'khoi11',
  'khoi12',
];

export const listPreschool = [
  '3_12thang',
  '13_24thang',
  '25_36thang',
  '3_4tuoi',
  '4_5tuoi',
  '5_6tuoi',
];

export const reportSchoolTypes = [
  {
    value: 'mn',
    label: '1. Mầm non',
  },
  {
    value: 'th',
    label: '2. Tiểu học',
  },
  {
    value: 'thcs',
    label: '3. Trung học cơ sở',
  },
  {
    value: 'thpt',
    label: '4. Trung học phổ thông',
  },
  {
    value: 'xmc',
    label: '5. Xóa mù chữ',
  },
  {
    value: 'khac',
    label: '6. Thống kê khác',
  },
];

export const disabilities = [
  {
    key: 'ktvandong',
    value: 'ktvandong',
    text: 'Khuyết tật vận động',
  },
  {
    key: 'ktnghenoi',
    value: 'ktnghenoi',
    text: 'Khuyết tật nghe, nói',
  },
  {
    key: 'ktnhin',
    value: 'ktnhin',
    text: 'Khuyết tật nhìn',
  },
  {
    key: 'ktthankinh',
    value: 'ktthankinh',
    text: 'Khuyết tật thần kinh, tâm thần',
  },
  {
    key: 'kttritue',
    value: 'kttritue',
    text: 'Khuyết tật trí tuệ',
  },
  {
    key: 'kthoctap',
    value: 'kthoctap',
    text: 'Khuyết tật học tập',
  },
  {
    key: 'tuky',
    value: 'tuky',
    text: 'Tự kỷ',
  },
  {
    key: 'ktkhac',
    value: 'ktkhac',
    text: 'Khuyết tật khác',
  },
  {
    key: 'khananghoctap',
    value: 'khananghoctap',
    text: 'Có khả năng học tập',
  },
  {
    key: 'chungnhankt',
    value: 'chungnhankt',
    text: 'Có chứng nhận khuyết tật',
  },
];

export const preschoolFields = [
  {
    key: 'twoSessions',
    text: 'Học 2 buổi',
    value: 'twoSessions',
  },
  {
    key: 'boardingSchool',
    text: 'Học bán trú',
    value: 'boardingSchool',
  },
  {
    key: 'learnEnough',
    text: 'Học đủ ngày theo quy định',
    value: 'learnEnough',
  },
  {
    key: 'diligence',
    text: 'Chuyên cần',
    value: 'diligence',
  },
  {
    key: 'followWeight',
    text: 'Trẻ được theo dõi BĐ cân nặng',
    value: 'followWeight',
  },
  {
    key: 'underweightSDD',
    text: 'SDD nhẹ cân',
    value: 'underweightSDD',
  },
  {
    key: 'followHeight',
    text: 'Trẻ được theo dõi BĐ chiều cao',
    value: 'followHeight',
  },
  {
    key: 'stuntingSDD',
    text: 'SDD thấp còi',
    value: 'stuntingSDD',
  },
  {
    key: 'ethnicVietnamese',
    text: 'Trẻ dân tộc được chuẩn bị Tiếng Việt',
    value: 'ethnicVietnamese',
  },
  {
    key: 'completePreschool',
    text: 'Hoàn thành chương trình GDMN',
    value: 'completePreschool',
  },
];

export const reportRequireWard = [
  'MN_ThongKeDSTraiTuyen',
  'MN_SoTheoDoiPCGD',
  'TH_HocNgoaiDiaBan',
  'TH_DSChuaDiHoc',
  'TH_ThongKeTraiTuyen',
  'TH_ThongKeDSTraiTuyen',
  'TH_SoPhoCapGDTH',
  'THCS_HocNgoaiDiaBan',
  'THCS_ThongKeTraiTuyen',
  'THCS_ThongKeDSTraiTuyen',
  'THCS_SoPhoCap',
  'THPT_SoPhoCap',
  'XMC_ThongKeDanhSach',
  'KHAC_TotNghiepNamQua',
  'KHAC_Tuoi1518TNTHCS',
  'KHAC_DSKhuyetTat',
  'KHAC_DSBoHoc',
  'KHAC_DSChuyenDi',
  'KHAC_DSDoiTuongChet',
  'KHAC_Tuoi11Den14TNTH',
  'KHAC_DSChuyenDen',
  'KHAC_TotNghiep5NamQua',
];

export const reportRequireDistrict = ['TH_DanhSachKTTH', 'KHAC_DSDiHocNoiKhac'];

export const ignoreReportFields = {
  MN_ThongKeLuaTuoi05: { sub: ['tre3den5t'] },
  THCS_ThongKeTNPCGD: { mainChild: ['bohoc_th', 'bohoc_thcs', 'bohoc_thpt'] },
};

export const sortDistrictProvinces = ['03'];
