import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { selectUserInformation } from './features/user/store/selectors';

const AuthorizedRoute = ({ children }) => {
  const location = useLocation();

  const user = useSelector(selectUserInformation);

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

AuthorizedRoute.propTypes = {
  children: PropTypes.node,
};

export default AuthorizedRoute;
