import { Button, Popconfirm, theme } from 'antd';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';

const RemoveButton = ({ confirmation, onConfirm, onClick }) => {
  const { token } = theme.useToken();
  const button = (
    <Button
      danger
      shape="circle"
      type="ghost"
      size="small"
      onClick={onClick}
      icon={<FaTrash color={token.colorError} />}
    />
  );
  if (confirmation) {
    return (
      <Popconfirm title={confirmation} onConfirm={onConfirm}>
        {button}
      </Popconfirm>
    );
  }
  return button;
};

RemoveButton.defaultProps = {
  confirmation: undefined,
  onConfirm: undefined,
  onClick: undefined,
};

RemoveButton.propTypes = {
  confirmation: PropTypes.string,
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
};

export default RemoveButton;
