import { createSlice } from '../../../utils/@reduxjs/toolkit';

export const initialState = {
  authorized: false,
  handling: false,
};
const slice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    fetchInformation(state, action) {
      state.information = action.payload ?? undefined;
      state.authorized = true;
      state.handling = false;
    },
    updateUserInformation(state, _action) {
      state.handling = true;
    },
    leave(state, _action) {
      state.handling = true;
    },
    joinUnit(state, _action) {
      state.handling = true;
    },
    signedIn(state, _action) {
      state.handling = true;
      state.information = undefined;
      state.authorized = false;
    },
    signOut(state) {
      state.information = undefined;
    },
  },
});

export const { actions, name: key, reducer } = slice;
