import { Divider, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

import useStyles from './styles';

const PageContent = ({ header, actions, content }) => {
  const S = useStyles();
  return (
    <Space className={S.container} direction="vertical">
      <div className={S.header}>
        <Typography.Title level={5}>{header}</Typography.Title>
        {actions}
      </div>
      <Divider className={S.divider} />
      {content}
    </Space>
  );
};

PageContent.propTypes = {
  header: PropTypes.node.isRequired,
  actions: PropTypes.node,
  content: PropTypes.node,
};

export default PageContent;
