import _ from 'lodash';
import moment from 'moment';

import {
  careerLevelsObject,
  classesObject,
  disabilities,
  grades,
  gradesObject,
  illiteracyFinishObject,
  illiteracyLevelObject,
  levelsObject,
  preschoolFields,
  priorities,
  residentObject,
  residentStatusObject,
  specialSituationObject,
} from '../../../configs';
import { parseAddress } from '../../../utils/helpers';
import { runSort } from '../../surveys/components/helpers';

export const expandSubject = (rawSubject, cities, ethnics, religions, allSchools, allVillages) => {
  const subject = _.cloneDeep(rawSubject);
  const newCodeSchool = _.replace(subject.codeSchoolYear, '!', '');
  subject.isHeadText = subject.isHead ? 'x' : '';
  subject.birthDateText = subject.birthDate ? moment(subject.birthDate).format('DD/MM/YYYY') : '';
  subject.genderText = subject.gender === 'M' ? 'Nam' : 'Nữ';
  subject.ethnicText = _.find(ethnics, (ethnic) => ethnic.code === subject.ethnic)?.name || '';
  subject.religionText =
    _.find(religions, (religion) => religion.code === subject.religion)?.name || '';
  subject.priorityText = _.get(
    _.find(priorities, (prioritie) => prioritie.value === subject.priority),
    'text',
    ''
  );
  subject.residentText = residentObject?.[subject.resident] || '';
  subject.residentStatusText = residentStatusObject?.[subject.residentStatus] || '';
  subject.surveyName = `${subject.firstNameSurvey} ${subject.lastNameSurvey}`;
  const schools = allSchools[subject.year];
  if (newCodeSchool) {
    if (!subject.provinceSchool || !subject.districtSchool) {
      const province = _.get(schools, [newCodeSchool, 'province'], '');
      const district = _.get(schools, [newCodeSchool, 'district'], '');
      subject.provinceSchool = subject.provinceSchool || province;
      subject.districtSchool = subject.districtSchool || district;
    }
  } else if (subject.districtSchool) {
    if (!subject.provinceSchool) {
      const { province } = parseAddress(subject.districtSchool);
      subject.provinceSchool = subject.provinceSchool || province;
    }
  }
  if (subject.repetition && !_.includes(subject.classSchoolYear, '!')) {
    subject.classSchoolYear = `${subject.classSchoolYear}!`;
  }

  subject.provinceSchoolText = _.get(cities[subject.provinceSchool], 'name', '');
  subject.districtSchoolText = _.get(
    cities[subject.provinceSchool],
    `districts.${subject.districtSchool}.name`,
    ''
  );
  subject.codeSchoolYearText = _.get(allSchools[subject.year], [newCodeSchool, 'name'], '');

  if (subject.livingWard) {
    if (!subject.livingProvince || !subject.livingDistrict) {
      const { province, district } = parseAddress(subject.livingWard);
      subject.livingProvince = subject.livingProvince || province;
      subject.livingDistrict = subject.livingDistrict || district;
    }
  } else if (subject.livingDistrict) {
    if (!subject.livingProvince) {
      const { province } = parseAddress(subject.livingWard);
      subject.livingProvince = subject.livingProvince || province;
    }
  }

  subject.livingProvinceText = _.get(cities[subject.livingProvince], 'name', '');
  subject.livingDistrictText = _.get(
    cities[subject.livingProvince],
    `districts.${subject.livingDistrict}.name`,
    ''
  );
  subject.livingWardText = _.get(
    cities[subject.livingProvince],
    `districts.${subject.livingDistrict}.wards.${subject.livingWard}.name`,
    ''
  );
  subject.villageText = _.get(
    allVillages,
    [subject.year, subject.ward, subject.village, 'name'],
    ''
  );

  subject.gradeText = gradesObject[subject.grade] || '';
  subject.graduationLevelText = levelsObject[subject.graduationLevel] || '';
  subject.graduationCareerLevelText = careerLevelsObject[subject.graduationCareerLevel] || '';

  subject.finishClassText = classesObject[subject.finishClass] || '';
  subject.dropOutClassText = classesObject[subject.dropOutClass] || '';

  subject.studyComplementaryText = subject.studyComplementary ? 'x' : '';
  subject.graduationComplementaryText = subject.graduationComplementary ? 'x' : '';
  subject.study9ClassWeekText = subject.study9ClassWeek ? 'x' : '';
  subject.repetitionText = subject.repetition ? 'x' : '';
  subject.twoSessionsText = subject.twoSessions ? 'x' : '';
  subject.learnEnoughText = subject.learnEnough ? 'x' : '';

  subject.preschoolText = subject.preschool ? 'x' : '';
  _.forEach(preschoolFields, (preschoolField) => {
    subject[`${preschoolField.value}Text`] = subject[preschoolField.value] ? 'x' : '';
  });

  subject.illiteracyFinishText = illiteracyFinishObject[subject.illiteracyFinish] || '';
  subject.illiteracyLevelText = illiteracyLevelObject[subject.illiteracyLevel] || '';
  subject.specialSituationText = specialSituationObject[subject.specialSituation] || '';

  _.forEach(disabilities, (disability) => {
    subject[`${disability.value}Text`] = subject[disability.value] ? 'x' : '';
  });

  subject.tempDeleteText = subject.tempDelete ? 'x' : '';

  return subject;
};

export const parseSubjects = (
  rawSubjects,
  {
    year,
    province,
    district,
    ward,
    village,
    dynamicFilters,
    dynamicSorts,
    cities,
    ethnics,
    religions,
    allSchools,
    ignoreExpand,
    allVillages,
  }
) => {
  const sortFields = _.values(dynamicSorts);
  const subjects = sortFields.length ? runSort(rawSubjects, sortFields) : rawSubjects;

  const isIgnore = ignoreExpand || sortFields.length;

  const subjectIds = [];
  let count = 0;
  const newSubjects = {};
  _.forEach(subjects, (subject) => {
    if (year && subject.year !== year) return;

    if (province && subject.province !== province) return;
    if (district && subject.district !== district) return;
    if (ward && subject.ward !== ward) return;
    if (village && subject.village !== village) return;

    if (_.get(dynamicFilters, 'advanced')) {
      const getGradeIndex = (gradeKey) => _.findIndex(grades, (grade) => gradeKey === grade.value);
      if (dynamicFilters.advanced.key === 'birthDateRange') {
        const { dateFrom, dateTo } = dynamicFilters.advanced;
        const birthDate = moment(subject.birthDate);
        if (dateFrom && birthDate < moment(dateFrom)) return;
        if (dateTo && birthDate > moment(dateTo)) return;
      } else if (dynamicFilters.advanced.key === 'birthYearRange') {
        const { yearFrom, yearTo } = dynamicFilters.advanced;
        const birthYear = moment(subject.birthDate).year();
        if (yearFrom && birthYear < yearFrom) return;
        if (yearTo && birthYear > yearTo) return;
      } else if (dynamicFilters.advanced.key === 'gradeRange') {
        const { gradeFrom, gradeTo } = dynamicFilters.advanced;
        const gradeIndex = getGradeIndex(subject.grade);
        if (gradeFrom && gradeIndex < getGradeIndex(gradeFrom)) return;
        if (gradeTo && gradeIndex > getGradeIndex(gradeTo)) return;
      } else if (dynamicFilters.advanced.key === 'finishClassRange') {
        const { finishClassFrom, finishClassTo } = dynamicFilters.advanced;
        const gradeIndex = getGradeIndex(subject.finishClass);
        if (finishClassFrom && gradeIndex < getGradeIndex(finishClassFrom)) return;
        if (finishClassTo && gradeIndex > getGradeIndex(finishClassTo)) return;
      } else if (dynamicFilters.advanced.key === 'dropOutClassRange') {
        const { dropOutClassFrom, dropOutClassTo } = dynamicFilters.advanced;
        const gradeIndex = getGradeIndex(subject.dropOutClass);
        if (dropOutClassFrom && gradeIndex < getGradeIndex(dropOutClassFrom)) return;
        if (dropOutClassTo && gradeIndex > getGradeIndex(dropOutClassTo)) return;
      }
    }

    let expandedSubject;
    if (
      _.some(
        dynamicFilters,
        (filterValue, filterKey) => filterKey !== 'custom' && filterKey !== 'advanced'
      )
    ) {
      expandedSubject = isIgnore
        ? subject
        : expandSubject(subject, cities, ethnics, religions, allSchools, allVillages);

      if (
        !_.every(dynamicFilters, (filterValue, filterKey) => {
          if (filterKey === 'custom' || filterKey === 'advanced' || filterKey === 'isExact') {
            return true;
          }
          if (!filterValue) {
            return true;
          }
          if (filterValue === ' ') {
            return !expandedSubject[filterKey];
          }
          if (filterKey === 'yearOld') {
            // TODO: remove later
            return moment(expandedSubject.birthDate).year() === _.toNumber(filterValue);
          }
          if (filterKey === 'ethnicMinority' && filterValue) {
            return _.lowerCase(expandedSubject?.ethnic) !== 'kinh' && expandedSubject?.ethnic;
          }
          if (filterKey === 'hasReligion' && filterValue) {
            return expandedSubject?.religion;
          }
          if (_.isArray(filterValue)) {
            return _.includes(filterValue, expandedSubject[filterKey]);
          }
          return dynamicFilters.isExact
            ? _.toLower(expandedSubject[filterKey]) === _.toLower(filterValue)
            : _.includes(_.toLower(expandedSubject[filterKey]), _.toLower(filterValue));
        })
      ) {
        return;
      }
    }

    subjectIds.push(subject.id);
    count += 1;

    newSubjects[subject.id] = isIgnore
      ? subject
      : expandedSubject ||
        expandSubject(subject, cities, ethnics, religions, allSchools, allVillages);
  });

  return {
    pageSubjects: newSubjects,
    totalSubjects: count,
    subjectIds,
  };
};

export const parseCrossSubjects = (
  subjects,
  { year, province, district, ward, cities, ethnics, religions, allSchools, allVillages }
) => {
  const subjectIds = [];
  let count = 0;
  const newSubjects = {};

  _.forEach(subjects, (subject) => {
    if (year && subject.year !== year) return;

    if (province && subject.province !== province) return;
    if (district && subject.district !== district) return;
    if (ward && subject.ward !== ward) return;

    subjectIds.push(subject.id);
    count += 1;

    newSubjects[subject.id] = expandSubject(
      subject,
      cities,
      ethnics,
      religions,
      allSchools,
      allVillages
    );
  });

  return {
    newSubjects,
    totalSubjects: count,
    subjectIds,
  };
};
