import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '5px 30px',
    backgroundColor: '#fff',
  },
});
