import { Button, Upload } from 'antd';
import PropTypes from 'prop-types';
import { FaFileExcel } from 'react-icons/fa';

const ImportExcel = ({ content, onConfirm }) => {
  const handleImportFile = (file) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      onConfirm(reader.result);
    };
  };
  return (
    <Upload
      multiple={false}
      showUploadList={false}
      beforeUpload={handleImportFile}
      accept=".xls,.xlsx"
    >
      <Button
        style={{
          backgroundColor: 'rgb(82, 189, 148)',
          color: '#fff',
          borderColor: 'rgb(82, 189, 148)',
        }}
        icon={<FaFileExcel size={10} />}
      >
        {content ?? 'Nhập excel'}
      </Button>
    </Upload>
  );
};

ImportExcel.propTypes = {
  content: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default ImportExcel;
