import _ from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { ACTIVE_YEAR, sortDistrictProvinces } from '../configs';

export const parseOptions = (data, sort = true) => {
  const options = _.map(data, (value, key) => ({
    value: value?.code ?? key,
    label: value?.name ?? value,
  }));
  if (sort) {
    return options.sort((itemOne, itemTwo) =>
      itemOne.label.localeCompare(itemTwo.label, 'vi', {
        numeric: true,
      })
    );
  }
  return options;
};

export const sortDistricts = (districts) => {
  const groupArray = ['Quận', 'Thành phố', 'Huyện'];
  const districtsArray = _.map(districts, (districtData, id) => ({ ...districtData, id }));
  const districtsGroup = _.groupBy(districtsArray, ({ name }) =>
    _.indexOf(
      groupArray,
      _.find(groupArray, (value) => _.includes(name, value))
    )
  );
  const districtSorted = _.flatten(
    Object.values(districtsGroup).map((groupValue) =>
      groupValue.sort((itemOne, itemTwo) =>
        itemOne.name.localeCompare(itemTwo.name, 'vi', {
          numeric: true,
        })
      )
    )
  );

  const newDistricts = _.cloneDeep(_.keyBy(districtSorted, 'id'));
  _.forEach(newDistricts, (value) => delete value.id);
  return newDistricts;
};

export const parseCities = (cities, year = ACTIVE_YEAR) => {
  const newCities = _.cloneDeep(cities);
  _.forEach(cities, (city, cKey) => {
    _.forEach(city.districts, (district, dKey) => {
      if (_.some(district.wards, (ward) => ward.changes)) {
        _.forEach(district.wards, (ward, wKey) => {
          const changes = ward?.changes;
          if (changes) {
            const changeYear = _.toNumber(Object.keys(changes)[0]);
            const changeData = changes[changeYear];
            if (year >= changeYear) {
              if (changeData?.merged) {
                if (changeData.merged === wKey) {
                  _.set(newCities, [cKey, 'districts', dKey, 'wards', wKey], {
                    ...ward,
                    name: changeData?.name || ward.name,
                  });
                } else {
                  _.unset(newCities, [cKey, 'districts', dKey, 'wards', wKey]);
                }
              }
              if (changeData?.moved?.district) {
                const {
                  district: newDistrict,
                  code,
                  name,
                  difficultyArea,
                  specialArea,
                } = changeData.moved;
                const wardData = _.get(newCities, [cKey, 'districts', dKey, 'wards', wKey], {});
                _.set(newCities, [cKey, 'districts', newDistrict, 'wards', code || wKey], {
                  ...wardData,
                  name: name || wardData.name,
                  oldCode: wKey,
                  oldDistrict: dKey,
                  difficultyArea: difficultyArea || false,
                  specialArea: specialArea || false,
                });
              }
            }
          }
        });
      }
      const dChanges = district?.changes;
      if (dChanges) {
        const changeYear = _.toNumber(Object.keys(dChanges)[0]);
        const changeData = dChanges[changeYear];
        if (year >= changeYear && changeData?.merged) {
          if (changeData.merged === dKey) {
            const districtData = _.get(newCities, [cKey, 'districts', dKey], {});
            _.set(newCities, [cKey, 'districts', dKey], {
              ...districtData,
              name: changeData?.name || districtData.name,
            });
          } else {
            _.unset(newCities, [cKey, 'districts', dKey]);
          }
        }
      }
    });
  });

  _.forEach(sortDistrictProvinces, (province) => {
    _.set(
      newCities,
      [province, 'districts'],
      sortDistricts(_.get(newCities, [province, 'districts'], {}))
    );
  });
  return newCities;
};

export const convertSpecialCharToUnicode = (str) => {
  let newStr = '';
  _.forEach(str, (char) => {
    switch (char) {
      case '\u0300'.normalize():
        newStr += '\u0300';
        break;
      case '\u0301'.normalize():
        newStr += '\u0301';
        break;
      case '\u0303'.normalize():
        newStr += '\u0303';
        break;
      case '\u0309'.normalize():
        newStr += '\u0309';
        break;
      case '\u0323'.normalize():
        newStr += '\u0323';
        break;
      default:
        newStr += char;
        break;
    }
  });
  return newStr.normalize();
};

export const getCheckExcelData = (value) => _.lowerCase(value) === 'x';

export const isValidDate = (d, m, y) => moment(`${d}/${m}/${y}`, 'DD/MM/YYYY').isValid();

export const getUniqueKey = (obj) => {
  const id = uuid();
  if (_.get(obj, id)) {
    return getUniqueKey(obj);
  }
  return id;
};

export const isSameSurveys = (surveyOne, surveyTwo) =>
  `${surveyOne.numberSurvey}${surveyOne.firstName}${surveyOne.lastName}${surveyOne.village}${surveyOne.year}` ===
  `${surveyTwo.numberSurvey}${surveyTwo.firstName}${surveyTwo.lastName}${surveyTwo.village}${surveyTwo.year}`;

export const isSameObjects = (subjectOne, subjectTwo) =>
  `${subjectOne.relationship}${subjectOne.firstName}${subjectOne.lastName}${moment(
    subjectOne.birthDate
  ).format('DD/MM/YYYY')}` ===
  `${subjectTwo.relationship}${subjectTwo.firstName}${subjectTwo.lastName}${moment(
    subjectTwo.birthDate
  ).format('DD/MM/YYYY')}`;

const isSameObjectPreschools = (subjectOne, subjectTwo) =>
  `${subjectOne.firstName}${subjectOne.lastName}` ===
  `${subjectTwo.firstName}${subjectTwo.lastName}`;

export const isSameStudent = (studentOne, studentTwo) =>
  `${studentOne.firstName}${studentOne.lastName}${moment(studentOne.birthDate).format(
    'DD/MM/YYYY'
  )}${studentOne.gender}${studentOne.grade}${studentOne.classSchoolYear}${
    studentOne.livingProvince
  }${studentOne.livingDistrict}${studentOne.livingWard}` ===
  `${studentTwo.firstName}${studentTwo.lastName}${moment(studentTwo.birthDate).format(
    'DD/MM/YYYY'
  )}${studentTwo.gender}${studentTwo.grade}${studentTwo.classSchoolYear}${
    studentTwo.livingProvince
  }${studentTwo.livingDistrict}${studentTwo.livingWard}`;

export const mergeSurveys = (surveyOne, surveyTwo, preschool) => {
  const mergedSubjects = _.cloneDeep(surveyOne.subjects) || {};
  _.forEach(surveyTwo.subjects, (newSubject) => {
    const subjectId =
      // eslint-disable-next-line no-confusing-arrow
      _.findKey(surveyOne.subjects, (subject) =>
        preschool ? isSameObjectPreschools(subject, newSubject) : isSameObjects(subject, newSubject)
      ) || newSubject.id;
    mergedSubjects[subjectId] = {
      ...mergedSubjects[subjectId],
      ...newSubject,
      id: preschool ? subjectId : newSubject.id,
    };
  });
  return {
    ...surveyOne,
    ...surveyTwo,
    id: surveyOne.id,
    subjects: mergedSubjects,
  };
};

export const getHeadSubject = (survey) =>
  _.find(survey.subjects, 'isHead') ||
  _.find(
    survey.subjects,
    (subject) => subject.firstName === survey.firstName && subject.lastName === survey.lastName
  ) || {
    id: getUniqueKey(survey.subjects),
    surveyId: survey.id,
    firstName: survey.firstName,
    lastName: survey.lastName,
    birthDate: survey.birthDate,
    gender: survey.gender,
    phone: survey.phone,
    note: survey.note,
    numberSurvey: survey.numberSurvey,
    address: survey.address,
    village: survey.village,
    resident: survey.resident,
    residentStatus: survey.residentStatus,
  };

export const getUnitType = (filter) => {
  const { province, district, ward } = filter;
  let type = '';
  if (province) {
    type = 'tinh';
  }
  if (district) {
    type = 'quan';
  }
  if (ward) {
    type = 'phuong';
  }
  return type;
};

export const unitLevel = (unitType) => {
  switch (unitType) {
    case 'tinh':
      return 3;
    case 'quan':
      return 2;
    case 'phuong':
      return 1;
    default:
      return 0;
  }
};

export const getSchoolId = (year, syncCode) => `${year}_${syncCode}`;

export const getAnalyticId = (year, reportKey, address) => `${year}-${reportKey}-${address}`;

export const getStatusKey = (year, unit) => `${year}-${unit}`;

export const getUnitInformation = (cities, filter) => {
  const results = {
    province: '',
    district: '',
    ward: '',
    year: '',
  };
  const { province, district, ward, year } = filter;
  if (province) {
    results.province = _.get(cities, [province, 'name'], province);
  }
  if (district) {
    results.district = _.get(cities, [province, 'districts', district, 'name'], district);
  }
  if (ward) {
    results.ward = _.get(cities, [province, 'districts', district, 'wards', ward, 'name'], ward);
  }
  if (year) {
    results.year = `${year}-${year + 1}`;
  }
  return results;
};

export const handleSort = (firstValue, secondValue) => {
  const firstValueLC = `${firstValue}`.toLocaleLowerCase('vi');
  const secondValueLC = `${secondValue}`.toLocaleLowerCase('vi');

  if (typeof firstValue === 'number') {
    return firstValueLC.localeCompare(secondValueLC, 'vi', {
      numeric: true,
    });
  }

  return firstValueLC.localeCompare(secondValueLC, 'vi', {
    ignorePunctuation: true,
  });
};

export const parseAddress = (address) => {
  const splits = _.split(address, '_');
  if (splits.length >= 4) {
    return {
      province: splits[0],
      district: `${splits[0]}_${splits[1]}`,
      ward: `${splits[0]}_${splits[1]}_${splits[2]}`,
      village: `${splits[0]}_${splits[1]}_${splits[2]}_${splits[3]}`,
    };
  }
  if (splits.length >= 3) {
    return {
      province: splits[0],
      district: `${splits[0]}_${splits[1]}`,
      ward: `${splits[0]}_${splits[1]}_${splits[2]}`,
    };
  }
  if (splits.length === 2) {
    return {
      province: splits[0],
      district: `${splits[0]}_${splits[1]}`,
    };
  }
  if (splits.length === 1) {
    return {
      province: splits[0],
    };
  }
  return {};
};
