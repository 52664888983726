export const BIG_SCREEN = {
  minWidth: '1920px',
};
export const NORMAL_SCREEN = {
  minWidth: '1024px',
};
export const SMALL_SCREEN = {
  maxWidth: '800px',
};
export const TINY_SCREEN = {
  maxWidth: '550px',
};
