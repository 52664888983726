import { Avatar, Button, Layout, Space, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { parseCities } from '../../../../utils/helpers';
import { selectActiveUnit, selectCities } from '../../../catalogs/store/selectors';
import ProfileDialog from '../../../user/components/ProfileDialog';
import { useUserSlice } from '../../../user/store';
import { selectUserInformation } from '../../../user/store/selectors';
import useStyles from './styles';

const Header = () => {
  const S = useStyles();
  const dispatch = useDispatch();
  const userInformation = useSelector(selectUserInformation);
  const activeUnit = useSelector(selectActiveUnit);
  const allCities = useSelector(selectCities);
  const { actions } = useUserSlice();

  const [showProfile, setShowProfile] = useState(false);

  const unitName = useMemo(() => {
    const { province, district, ward } = activeUnit;
    let name = '';
    const cities = parseCities(allCities);
    if (ward) {
      name = cities?.[province]?.districts?.[district]?.wards?.[ward]?.name;
    } else if (district) {
      name = cities?.[province]?.districts?.[district]?.name;
    } else {
      name = cities?.[province]?.name;
    }
    return name;
  }, [activeUnit, allCities]);

  return (
    <Layout.Header className={S.container}>
      <Space className={S.leftHeader}>
        <Avatar className={S.logo} src="/icon-192x192.png" />
        <Space.Compact direction="vertical">
          <Typography.Text strong>THỐNG KÊ PHỔ CẬP GIÁO DỤC - XÓA MÙ CHỮ</Typography.Text>
          <Typography.Text>{unitName}</Typography.Text>
        </Space.Compact>
      </Space>
      <Space key="avatar">
        <Avatar src={userInformation.photoURL} />
        <Typography.Text onClick={() => setShowProfile(true)}>
          {userInformation?.displayName ?? userInformation?.email}
        </Typography.Text>
        <Button type="text" icon={<MdLogout />} onClick={() => dispatch(actions.signOut())} />
      </Space>
      {showProfile ? <ProfileDialog onCancel={() => setShowProfile(false)} /> : null}
    </Layout.Header>
  );
};

export default Header;
