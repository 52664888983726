import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);

const CustomDatePicker = ({ value, onChange, style, format }) => (
  <MyDatePicker
    value={value ? moment(value) : ''}
    onChange={(date) => {
      if (onChange) {
        onChange(date.toDate().getTime());
      }
    }}
    style={style}
    format={format || 'DD/MM/YYYY'}
  />
);

CustomDatePicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.shape({}),
  format: PropTypes.string,
};

export default CustomDatePicker;
