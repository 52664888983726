import { BACKEND } from '../configs';
import Api from './api';

export const backendService = new Api({
  baseURL: BACKEND,
  withCredentials: true,
});

export const uploadService = new Api({
  baseURL: BACKEND,
  withCredentials: true,
  headers: { 'content-type': 'multipart/form-data' },
});
