import { Spin } from 'antd';

import useStyles from './styles';

const Waiting = () => {
  const S = useStyles();
  return (
    <div className={S.container}>
      <Spin />
    </div>
  );
};

export default Waiting;
