import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'moment/locale/vi';
import './utils/datetime';
import './firebase';

import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import FontFaceObserver from 'fontfaceobserver';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import ErrorBoundary from './pages/ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import { configureAppStore } from './store';
import { form, theme } from './styles';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Inter', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ErrorBoundary>
        <ConfigProvider locale={viVN} form={form} theme={theme}>
          <App />
        </ConfigProvider>
      </ErrorBoundary>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
