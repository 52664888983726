import { Button, Layout, Space, Typography } from 'antd';
import { AiOutlineBook } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { VscRemoteExplorer } from 'react-icons/vsc';
import { useTheme } from 'react-jss';

import packageJson from '../../../../../package.json';
import useStyles from './styles';

const Footer = () => {
  const theme = useTheme();
  const S = useStyles();
  const guide = '';

  return (
    <Layout.Footer className={S.container}>
      <Typography.Text>
        {theme.isTinyScreen
          ? `PCGD-XMC v${packageJson.version}`
          : `PCGD-XMC v${packageJson.version} • © 2023, Sở Giáo Dục Đào Tạo TP.HCM.`}
      </Typography.Text>
      <Space>
        <Button
          type="text"
          size="small"
          icon={<VscRemoteExplorer size={12} />}
          onClick={() => window.open('https://ultraviewer.net/vi/', 'Hỗ trợ từ xa', 'location:no')}
        >
          Hỗ trợ từ xa
        </Button>
        {guide ? (
          <Button
            type="text"
            size="small"
            icon={<AiOutlineBook size={12} />}
            onClick={() => window.open(guide, 'Hướng dẫn sử dụng' ?? 'guide', 'location:no')}
          >
            Hướng dẫn sử dụng
          </Button>
        ) : null}
        {window.fcWidget ? (
          <Button
            type="text"
            size="small"
            icon={<BiSupport size={12} />}
            onClick={() => window.fcWidget?.open()}
          >
            Hỗ trợ
          </Button>
        ) : null}
      </Space>
    </Layout.Footer>
  );
};

export default Footer;
