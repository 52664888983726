import _ from 'lodash';

import { createSlice } from '../../../utils/@reduxjs/toolkit';

export const initialState = { system: {}, unit: {} };

const slice = createSlice({
  name: 'catalogStore',
  initialState,
  reducers: {
    fetchSystemCatalog(state, action) {
      if (action.payload?.year) {
        _.set(state, ['system', action.payload.catalog], {
          handling: false,
          data: {
            ...(state?.system?.[action.payload.catalog]?.data || {}),
            [action.payload?.year]: action.payload.data,
          },
        });
      } else {
        _.set(state, ['system', action.payload.catalog], {
          handling: false,
          data: action.payload.data,
        });
      }
    },
    fetchUnitCatalog(state, action) {
      const { catalog, data } = action.payload;
      state.unit.handling = false;
      if (catalog) {
        _.set(state, ['unit', 'data', catalog], data);
      } else {
        state.unit.data = data;
      }
    },
    getSystemCatalogs(state) {
      ['cities', 'ethnics', 'religions'].forEach((catalog) => {
        _.set(state, ['system', catalog, 'handling'], true);
      });
    },
    getVillages(state, _action) {
      _.set(state, ['system', 'villages', 'handling'], true);
    },
    getConfigs(state, _action) {
      _.set(state, 'unit.handling', true);
    },
    getSchools(state, _action) {
      _.set(state, ['system', 'schools', 'handling'], true);
    },
    cacheSchools(state, _action) {
      _.set(state, ['system', 'schools', 'handling'], true);
    },
    getUnitCatalogs(state, action) {
      if (action.payload) {
        _.set(state, 'unit.handling', true);
      }
      _.unset(state.unit, 'data');
    },
    updateUnitInformation(state, _action) {
      state.unit.handling = true;
    },
    saveCatalog(state, _action) {
      state.unit.handling = true;
    },
    updateConfigs(state, _action) {
      state.unit.handling = true;
    },
    updateCatalog(state, action) {
      state.unit.handling = false;
      if (action.payload) {
        const { catalog, items } = action.payload;
        if (Array.isArray(items)) {
          items.forEach(({ id, data }) => {
            if (data) {
              _.set(state.unit, ['data', catalog, id], data);
            } else {
              _.unset(state.unit, ['data', catalog, id]);
            }
          });
        } else {
          _.set(state.unit, ['data', catalog], items);
        }
      }
    },
  },
});

export const { actions, name: key, reducer } = slice;
