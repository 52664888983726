export const BACKEND = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return 'http://localhost:3031';
    case 'qa':
      return 'https://qapcgd.back3nd.xyz';
    default:
      return 'https://pcgd.back3nd.xyz';
  }
})();

export const NOTIFICATIONS_AIO = 'https://aios.back3nd.xyz/api/notifications';
export const RESOURCES = `${BACKEND}/resources`;
