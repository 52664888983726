import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& h5': {
      margin: '0.1em 0 0 0',
    },
  },
  divider: {
    margin: 5,
  },
});
