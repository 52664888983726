import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './reducer';

const selectDomain = (state) => state?.userStore || initialState;

export const selectUserInformation = createSelector([selectDomain], (state) => state.information);

export const selectUserAuthorized = createSelector([selectDomain], (state) => state.authorized);

export const selectUserHandling = createSelector([selectDomain], (state) => state.handling);
