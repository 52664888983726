export const form = {
  validateMessages: {
    default: 'default',
    required: 'required',
    date: {
      format: 'wrong date format',
      parse: 'wrong date format',
      invalid: 'invalid date',
    },
    string: {
      len: 'invalid length',
      min: 'invalid length',
      max: 'invalid length',
      range: 'invalid length',
    },
    number: {
      len: 'invalid number',
      min: 'invalid number',
      max: 'invalid number',
      range: 'invalid number',
    },
    array: {
      len: 'invalid array',
      min: 'invalid array',
      max: 'invalid array',
      range: 'invalid array',
    },
    pattern: {
      mismatch: 'wrong syntax',
    },
  },
};

export const theme = {
  token: {
    colorBgLayout: 'transparent',
  },
};
