import _ from 'lodash';

export default (error) => {
  if (error.code) {
    return `Firebase error: ${error.code}`;
  }

  if (error.kind) {
    if (_.get(error, 'error.response.data.errors')) {
      return _.get(error, 'error.response.data.errors', [])
        .map(({ message }) => message)
        .join(', ');
    }
    if (_.get(error, 'error.response.data')) {
      return error?.error?.response?.data;
    }
    return error?.error?.message;
  }

  if (error.errors) {
    return Object.values(error.errors).join(', ');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unkown error';
};
