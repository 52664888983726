import { Empty, Modal, notification, Timeline, Typography } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { backendService } from '../../services';
import Waiting from '../Waiting';
import useStyles from './styles';

const Logs = ({ unit, type, onCancel }) => {
  const S = useStyles();

  const [logs, setLogs] = useState([]);
  const [handling, setHandling] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setHandling(true);
        const result = await backendService.post('/api/getLogs', {
          unit,
          type,
        });
        if (result.kind === 'ok') {
          const data = _.orderBy(result.data, ['time'], ['desc']);
          setLogs(data);
        } else {
          notification.warning({
            message: 'Lỗi',
            description: 'Tải lịch sử hoạt động thất bại',
          });
        }
      } finally {
        setHandling(false);
      }
    })();
  }, [type, unit]);

  const items = useMemo(
    () =>
      _.map(logs, ({ time, message, user }) => ({
        children: (
          <>
            <Typography.Text type="secondary">
              {user} - {moment(time).format('D/M/Y LT')}
            </Typography.Text>
            <Typography>
              {_.map(_.split(message, '\n'), (content, index) => (
                <Typography key={index}>{content}</Typography>
              ))}
            </Typography>
          </>
        ),
      })),
    [logs]
  );

  return (
    <Modal
      open
      onCancel={onCancel}
      title="Lịch sử hoạt động"
      bodyStyle={{ maxHeight: window.innerHeight - 250, overflow: 'auto' }}
      footer={null}
    >
      {handling ? <Waiting /> : null}
      <div className={S.container}>
        {_.size(logs) ? (
          <Timeline items={items} />
        ) : (
          <Empty description="Chưa có lịch sử hoạt động" />
        )}
      </div>
    </Modal>
  );
};

Logs.propTypes = {
  unit: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Logs;
