import _ from 'lodash';
import moment from 'moment';

import { residentObject, residentStatusObject } from '../../../configs';

const compareItems = (field, item1, item2) => {
  const str1 = (item1[field.key] || '').toLocaleLowerCase('vi');
  const str2 = (item2[field.key] || '').toLocaleLowerCase('vi');

  if (field.type === 'number') {
    return field.order === 'desc'
      ? str2.localeCompare(str1, 'vi', {
          numeric: true,
        })
      : str1.localeCompare(str2, 'vi', {
          numeric: true,
        });
  }
  return field.order === 'desc'
    ? str2.localeCompare(str1, 'vi', {
        ignorePunctuation: true,
      })
    : str1.localeCompare(str2, 'vi', {
        ignorePunctuation: true,
      });
};

export const runSort = (rawData, sortFields) => {
  const arr = _.values(rawData);
  if (sortFields.length === 1) {
    arr.sort((item1, item2) => compareItems(sortFields[0], item1, item2));
  } else {
    arr.sort((item1, item2) => {
      let result = 0;
      let index = 0;
      let sortField = sortFields[index];
      while (sortField && result === 0) {
        result = compareItems(sortField, item1, item2);
        index += 1;
        sortField = sortFields[index + 1];
      }
      return result;
    });
  }
  return arr;
};

export const expandSurvey = (rawSurvey, allVillages) => {
  const survey = _.cloneDeep(rawSurvey);

  survey.birthDateText = survey.birthDate ? moment(survey.birthDate).format('DD/MM/YYYY') : '';
  survey.genderText = survey.gender === 'M' ? 'Nam' : 'Nữ';
  survey.villageText = _.get(allVillages[survey.year], [survey.ward, survey.village, 'name'], '');
  survey.residentText = residentObject?.[survey.resident] || '';
  survey.residentStatusText = residentStatusObject?.[survey.residentStatus] || '';
  survey.timeText = survey.time ? moment(survey.time).format('DD/MM/YYYY') : '';

  return survey;
};

export const parseSurveys = (
  rawSurveys,
  {
    year,
    province,
    district,
    ward,
    village,
    dynamicFilters,
    dynamicSorts,
    ignoreExpand,
    allVillages,
  }
) => {
  const surveyIds = [];
  let count = 0;
  let countSubjects = 0;

  const sortFields = _.values(dynamicSorts);
  const surveys = sortFields.length ? runSort(rawSurveys, sortFields) : rawSurveys;

  const isIgnore = ignoreExpand || sortFields.length;
  const newSurveys = {};
  _.forEach(surveys, (survey) => {
    if (survey.year !== year) return;

    if (province && survey.province !== province) return;
    if (district && survey.district !== district) return;
    if (ward && survey.ward !== ward) return;
    if (village && survey.village !== village) return;

    if (_.get(dynamicFilters, 'advanced')) {
      if (dynamicFilters.advanced.key === 'birthDateRange') {
        const { dateFrom, dateTo } = dynamicFilters.advanced;
        const birthDate = moment(survey.birthDate);
        if (dateFrom && birthDate < moment(dateFrom)) return;
        if (dateTo && birthDate > moment(dateTo)) return;
      } else if (dynamicFilters.advanced.key === 'birthYearRange') {
        const { yearFrom, yearTo } = dynamicFilters.advanced;
        const birthYear = moment(survey.birthDate).year();
        if (yearFrom && birthYear < yearFrom) return;
        if (yearTo && birthYear > yearTo) return;
      }
    }

    let expandedSurvey;
    if (
      _.some(
        dynamicFilters,
        (filterValue, filterKey) => filterKey !== 'custom' && filterKey !== 'advanced'
      )
    ) {
      expandedSurvey = isIgnore ? survey : expandSurvey(survey, allVillages);

      if (
        !_.every(dynamicFilters, (filterValue, filterKey) => {
          if (filterKey === 'custom' || filterKey === 'advanced' || filterKey === 'isExact') {
            return true;
          }
          if (!filterValue) {
            return true;
          }
          if (filterValue === ' ') {
            return !expandedSurvey[filterKey];
          }
          if (_.isArray(filterValue)) {
            return _.includes(filterValue, expandedSurvey[filterKey]);
          }
          return dynamicFilters.isExact
            ? _.toLower(expandedSurvey[filterKey]) === _.toLower(filterValue)
            : _.includes(_.toLower(expandedSurvey[filterKey]), _.toLower(filterValue));
        })
      ) {
        return;
      }
    }
    surveyIds.push(survey.id);

    const subjects = {};
    let numSubjects = 0;
    _.forEach(survey.subjects, (subject) => {
      numSubjects += 1;
      subjects[subject.id] = subject;
    });

    countSubjects += numSubjects;
    count += 1;

    newSurveys[survey.id] = {
      ...(isIgnore ? survey : expandedSurvey || expandSurvey(survey, allVillages)),
      subjects,
      numSubjects,
    };
  });

  return {
    pageSurveys: newSurveys,
    totalSurveys: count,
    totalSubjects: countSubjects,
    surveyIds,
  };
};
