import { getAnalytics, logEvent } from 'firebase/analytics';

import { app } from '../firebase';

const analytics = getAnalytics(app);

export const screenView = (screenName) => {
  logEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
  });
};
