import { Button, Form, Input, Modal, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { UnitSelector, Waiting } from '../../../../components';
import { screenView } from '../../../../utils/firebase';
import { useUserSlice } from '../../store';
import { selectUserHandling, selectUserInformation } from '../../store/selectors';
import useStyles from './styles';

const ProfileDialog = (props) => {
  const dispatch = useDispatch();
  const userInformation = useSelector(selectUserInformation);
  const handling = useSelector(selectUserHandling);
  const { actions } = useUserSlice();
  const S = useStyles();

  const [openSelector, setOpenSelector] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => screenView('Thông tin tài khoản'), []);

  const saveProfile = async () => {
    try {
      await form.validateFields(['displayName']);
      const formData = form.getFieldsValue();
      const updateData = {
        ...formData,
        male: formData.gender === 'm',
      };
      delete updateData.gender;
      dispatch(actions.updateUserInformation(updateData));
      props.onCancel();
    } catch {}
  };

  return (
    <Modal
      {...props}
      title="Thông tin cá nhân"
      open
      footer={[
        <Button className={S.leftButton} key="switch" onClick={() => setOpenSelector(true)}>
          Chuyển đơn vị
        </Button>,
        <Button
          className={S.leftButton}
          key="leave"
          danger
          onClick={() => dispatch(actions.leave())}
        >
          Rời đơn vị
        </Button>,
        <Button key="submit" type="primary" onClick={saveProfile} icon={<FaSave size={10} />}>
          Lưu
        </Button>,
      ]}
    >
      {handling ? <Waiting /> : null}
      {openSelector ? <UnitSelector onClose={() => setOpenSelector(false)} /> : null}
      <Form
        form={form}
        initialValues={{
          email: userInformation?.email ?? '',
          displayName: userInformation?.displayName ?? '',
          gender: userInformation?.male ? 'm' : 'f',
          phoneNumber: userInformation?.phoneNumber ?? '',
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ offset: 1 }}
      >
        <Form.Item name="email" label="Email">
          <Typography.Text>{userInformation?.email}</Typography.Text>
        </Form.Item>
        <Form.Item name="displayName" label="Tên" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Số điện thoại">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="gender" label="Giới tính">
          <Select
            options={[
              { label: 'Nam', value: 'm' },
              { label: 'Nữ', value: 'f' },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ProfileDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ProfileDialog;
